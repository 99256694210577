import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import { Input, Form, Upload, Button,notification } from "antd";
import Header from "./Header";
import Select from "react-select";
import { UploadOutlined } from "@ant-design/icons";

const menuItems = [
  { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];
const currencyOptions = [
  { value: "$", label: "US Dollar" },
    { value: "€", label: "Euro" },
    { value: "£", label: "British Pound Sterling" },
    { value: "¥", label: "Japanese Yen" },
    { value: "CHF", label: "Swiss Franc" },
    { value: "C$", label: "Canadian Dollar" },
    { value: "A$", label: "Australian Dollar" },
    { value: "元 ", label: "Chinese Yuan" },
    { value: "₹ ", label: "Indian Rupee" },
    { value: "R$", label: "Brazilian Real" },
    { value: "₽", label: "Russian Ruble" },
    { value: "R", label: "South African Rand" },
    { value: "Mex$", label: "Mexican Peso" },
    { value: "S$", label: "Singapore Dollar" },
    { value: "€", label: "Euro" },
];
const CompanyInfo = () => {
  
  const navigate = useNavigate();
  const [companyname, setCompanyname] = useState("");
  const [address, setAddress] = useState("");
  const [copyrights, setCopyrights] = useState("");
  const [phone, setPhone] = useState([""]);
  const [email, setEmail] = useState(null);
  const [fromtime, setFromTime] = useState("");
  const [totime, setToTime] = useState("");
  const [days, setDays] = useState([]);
  const [currency, setCurrency] = useState([{ value: "", label: "" }]);
  const [selectedDay, setSelectedDay] = useState([""]);
  const [logo, setLogo] = useState("");
  const [gst, setGST] = useState("");
  const [ssm, setSSM] = useState("");

  const addMobileNumber = () => {
    setPhone([...phone, ""]); // Add a new empty input for mobile number
  };
  const addCurrency = () => {
    setCurrency([...currency, ""]);
  };
  const handleMobileNumberChange = (index, event) => {
    const updatedMobileNumbers = [...phone];
    updatedMobileNumbers[index] = event.target.value;
    setPhone(updatedMobileNumbers);
  };
  const handleCurrencyChange = (index, selectedOption) => {
    const updatedCurrencies = [...currency];
    updatedCurrencies[index] = selectedOption;
    setCurrency(updatedCurrencies);
  };
  const addDayTimeField = () => {
    setSelectedDay([...selectedDay, null]);
  };

  const daysOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  
  const handleImageUpload = async (options) => {
    try {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/logo/add",
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      if (response.status === 200) {
        console.log("Image uploaded successfully");
        onSuccess();
      } else {
        console.error("Image upload failed");
        onError("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      onError("Error uploading image");
    }
  };

  const onError = (error) => {
    console.error("Image upload failed:", error);
    // You can add additional error handling logic here if needed
  };

  const onFinish = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try{
      const daysValues = selectedDay.map(day => day.value);
      const response = await fetch("http://68.178.169.226:12080/api/v1/logo/addall",{
        method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
      body: JSON.stringify({
        phone,
        email,
        days: daysValues,
        fromtime,
        totime,
        // currency,
        address,
        companyname,  
        copyrights,
        gstnumber:gst,
        ssm,
      }),
      });
      if (response.status === 401 || response.status === 404) { 
        // Redirect to the login page for 401 or 404 errors
        // navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
        }
        notification.success({
          message: "Profile Updated Successfully",
          duration: 3, // Duration in seconds
        });
    }
    catch (error) {
		  console.error("Error posting data:", error);
		}
  }
  useEffect(() => {
		const fetchLogo = async () => {
		  try {
			const response = await fetch('http://68.178.169.226:12080/api/v1/logo/getall',{
			  method: 'PUT',
			  headers: {
				'Content-Type': 'application/json',
				
			  },
			});
			if (response.ok) {
			  const data = await response.json();
        setCompanyname(data.companyname);
        setAddress(data.address);
        setPhone(data.phone);
        setEmail(data.email);
        setFromTime(data.fromtime);
        setToTime(data.totime);
        setSelectedDay(data.days.map(day => ({ value: day, label: day })));
        setCurrency(data.currency.map(curr => ({ value: curr, label: curr })));
        setCopyrights(data.copyrights);
        setLogo(data.logo);
        setGST(data.gstnumber);
        setSSM(data.ssm);
			} else {
			  console.error('Failed to fetch logo');
			}
		  } catch (error) {
			console.error('Error fetching logo:', error);
		  }
		};
	
	 	fetchLogo();
	   }, []); 
  return (
    <div className="wrapper">
      {/* <!-- LEFT MAIN SIDEBAR --> */}
      <SideBar />

      <div className="ec-page-wrapper">
      {/* <!-- Header --> */}
      <Header />

      {/* <!-- CONTENT WRAPPER --> */}
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>Company Info</h1>
            <p className="breadcrumbs">
              <span>
                <a href="index">Home</a>
              </span>
              <span>
                <i className="mdi mdi-chevron-right"></i>
              </span>
              Company Info
            </p>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="ec-cat-list card card-default mb-24px">
                <div className="card-body">
                  <div className="ec-cat-form">
                    <h4>Add Company Info</h4>
                
                    <div className="form-group row">
                      <label className="col-12 col-form-label">
                        Upload Logo
                      </label>
                      <div className="col-12">
                      {logo && <img src={logo} alt="Company Logo" width={250}   />}
                        <Upload
                          customRequest={handleImageUpload} // Custom request to handle the file
                          showUploadList={false} // Hide the default upload list
                          accept="image/*" // Accept only image files
                          onError={onError}
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload Image
                          </Button>
                        </Upload>
                      </div>
                    </div>
                    <form onSubmit={onFinish}>
                    <div className="form-group row">
                        <label className="col-12 col-form-label">
                          Company Name
                        </label>
                        <div className="col-12">
                          <input
                            id="sortdescription"
                            name="sortdescription"
                            cols="40"
                            rows="2"
                            className="form-control"
                            value={companyname}
                            onChange={(e) => setCompanyname(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-12 col-form-label">
                          Address
                        </label>
                        <div className="col-12">
                          <textarea
                            id="sortdescription"
                            name="sortdescription"
                            cols="40"
                            rows="2"
                            className="form-control"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-12 col-form-label">
                          Mobile Numbers
                        </label>
                        <div className="col-12">
                          {phone.map((number, index) => (
                            <div key={index} className="input-group mb-2">
                              <input
                                type="text"
                                className="form-control"
                                value={number}
                                onChange={(event) =>
                                  handleMobileNumberChange(index, event)
                                }
                              />
                              {index === phone.length - 1 && (
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={addMobileNumber}
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className="form-group row">
                      <div className="col-lg-3 col-md-4">
  <label className="col-form-label">Day</label>
  {selectedDay.map((days, index) => (
    <Select
      key={index}
      options={daysOptions}
      value={days}
      onChange={(selectedOption) => {
        const updatedSelectedDays = [...selectedDay];
        updatedSelectedDays[index] = selectedOption;
        setSelectedDay(updatedSelectedDays);
      }}
      isSearchable
    />
  ))}
</div>
<div className="col-lg-3 col-md-4">
  <button
    className="btn btn-secondary"
    type="button"
    onClick={addDayTimeField}
  >
    +
  </button>
</div>
                        <div className="col-lg-3 col-md-4">
                          <label className="col-form-label">From Time</label>
                          <input 
                          nput
                          id="text"
                          name="text"
                          className="form-control here slug-title"
                          value={fromtime} 
                          onChange={(e) => setFromTime(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <label className="col-form-label">To Time</label>
                          <input
                          nput
                          id="text"
                          name="text"
                          className="form-control here slug-title"
                          value={totime}
                          onChange={(e) => setToTime(e.target.value)} />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label for="text" className="col-12 col-form-label">
                          Mail Id
                        </label>
                        <div className="col-12">
                          <input
                            id="text"
                            name="text"
                            className="form-control here slug-title"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off" 
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label className="col-12 col-form-label">
                          Currency
                        </label>
                        <div className="col-12">
                          {currency.map((currencyObj, index) => (
                            <div key={index} className="input-group mb-2">
                               <Select
                    options={currencyOptions}
                    value={currencyOptions.find((option) => option.value === currencyObj)}
                    onChange={(selectedOption) => handleCurrencyChange(index, selectedOption.value)}
                  />
                               {index === currency.length - 1 && (
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={addCurrency}
                      >
                        +
                      </button>
                    </div>
                  )}
                            </div>
                          ))}
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label for="text" className="col-12 col-form-label">
                          GST
                        </label>
                        <div className="col-12">
                          <input
                            id="text"
                            name="text"
                            className="form-control here slug-title"
                            type="text"
                            value={gst}
                            onChange={(e) => setGST(e.target.value)}
                            autoComplete="off" 
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="text" className="col-12 col-form-label">
                          SSM
                        </label>
                        <div className="col-12">
                          <input
                            id="text"
                            name="text"
                            className="form-control here slug-title"
                            type="text"
                            value={ssm}
                            onChange={(e) => setSSM(e.target.value)}
                            autoComplete="off" 
                          />
                        </div>
                      </div>
                      <div className="form-group row">
												<label className="col-12 col-form-label">Copy Rights</label> 
												<div className="col-12">
													<textarea id="sortdescription" name="sortdescription" cols="40" rows="2" className="form-control"value={copyrights}
                            onChange={(e) => setCopyrights(e.target.value)}></textarea>
												</div>
											</div> 
                      <div className="row">
                        <div className="col-12">
                          <button
                            name="submit"
                            type="submit"
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CompanyInfo;
