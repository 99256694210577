// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../../src/editCnfirmation/ConfirmationModal";

const EditCurrency = ({
  category,
  show,
  onHide,
  onSave,
  isLoadingEdit,
  editChitDetails,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  // Define state variables for form fields
  const [country, setCountry] = useState(category.country);
  const [currencycode, setcurrencycode] = useState(category.currencycode);
  const [symbol, setsymbol] = useState(category.symbol);
  const [language, setlanguage] = useState(category.language);
  const [languagecode, setlanguagecode] = useState(category.languagecode);
  const [mobileprefix, setCountryMobilecode] =useState(category.mobileprefix);
  const [mobilemaxlength, setMobilemaxlength] =useState(category.mobilemaxlength);
  const [fileid, setFileid] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useEffect(() => {
    setFileid(category.countryimageid); // Assuming `countryimageid` contains the URL of the already added image
  }, [category]);
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
      setFileid(category.fileModel ? category.fileModel.file : null);
  },[category]);

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFileid(imageFile);
  };
  

  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("http://68.178.169.226:12080/api/v1/country/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
  const handleImageUpload = async () => {
    try {
      setLoading(true);
      if (fileid) {
        const formData = new FormData();
        formData.append("file", fileid);
  
        const uploadResponse = await axios.post(
          "http://68.178.169.226:12080/api/v1/fileupload/file",
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (uploadResponse.status === 401 || uploadResponse.status === 404) {
          navigate("/AdminLogin");
          return;
        }
  
        const newOrganiserId = uploadResponse.data.organiserId;
      setFileid(newOrganiserId);

      // Store organiserId in session storage
      sessionStorage.setItem("organiserId", newOrganiserId);

      console.log("Image uploaded successfully");
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };
  const handleSaveChanges = async () => {
    // Create an object with the updated values
    const fileid = sessionStorage.getItem("organiserId");
    const updatedCategory = {
        countryselectionid: category.countryselectionid, // Include the category ID for identification
        country,
        currencycode,
        symbol,
        language,
        languagecode,
        countryimageid:category.countryimageid ? category.countryimageid : fileid,
        mobileprefix,
        mobilemaxlength,
    };
  
    try {
      setLoading(true);
  
      // Make an API request to update the category without uploading the image
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/country/addedit",
        updatedCategory,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
  
      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(updatedCategory); // Notify the parent component about the update
        onHide(); // Close the modal
      } else {
        console.error("Failed to update category");
      }
  
      setLoading(false);
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };
  
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    // Proceed with saving changes
    handleSaveChanges();
  };  

  return (
    <Modal show={show} onHide={onHide}>
      {/* Modal content */}
      <Modal.Header closeButton>
        <Modal.Title>Edit Country</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="Country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="currencycode">
            <Form.Label>Currency Code</Form.Label>
            <Form.Control
              type="text"
              name="currencycode"
              value={currencycode}
              onChange={(e) => setcurrencycode(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="Country">
            <Form.Label>Mobile No. Max Length</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={mobilemaxlength}
              onChange={(e) => setMobilemaxlength(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="mobileprefix">
            <Form.Label>Country phnonenumber Code</Form.Label>
            <Form.Control
              type="text"
              name="mobileprefix"
              value={mobileprefix}
              onChange={(e) => setCountryMobilecode(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="language">
            <Form.Label>Language</Form.Label>
            <Form.Control
              type="text"
              name="language"
              value={language}
              onChange={(e) => setlanguage(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="languagecode">
            <Form.Label>Language Code</Form.Label>
            <Form.Control
              type="text"
              name="languagecode"
              value={languagecode}
              onChange={(e) => setlanguagecode(e.target.value)}
            />
          </Form.Group>


          <Form.Group controlId="symbol">
            <Form.Label>Symbol</Form.Label>
            <Form.Control
              type="text"
              name="symbol"
              value={symbol}
              onChange={(e) => setsymbol(e.target.value)}
            />
          </Form.Group>
         <Form.Group controlId="fileid">
  <Form.Label>Select to replace Country Image</Form.Label>
  <Form.Control
    type="file"
    name="fileid"
    onChange={(e) => handleImageChange(e)}
  />
</Form.Group> <Button variant="primary" onClick={handleImageUpload} disabled={loading}>
  Upload Image
</Button>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleShowConfirmationModal} disabled={loading}>
          Save Changes
        </Button>
        
      </Modal.Footer>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Modal>
  );
};

export default EditCurrency;
