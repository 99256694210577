import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Modal, Table, Checkbox, InputNumber,Select,notification  } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import "../style.scss";
import "../riot_admin.scss";
import SideBar from "../sidebar";
import Header from "../Header";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { Option } = Select;
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
  
    useEffect(() => {
      if (editing && inputRef.current) {
        inputRef.current.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
  
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
  
    let childNode = children;
  
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'checkbox' ? (
            <Checkbox ref={inputRef} onClick={save} />
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      ) : dataIndex === 'checkbox' ? (
        <Checkbox onClick={toggleEdit} />
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };
 
const ShowPincodes = () => {
    const navigate = useNavigate();
  const [count, setCount] = useState(2);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [selectAll, setSelectAll] = useState(false); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountryforpincodeAdd, setSelectedCountryforpincodeAdd] = useState('');
  const [selectedStateforpincodeAdd, setSelectedStateforpincodeAdd] = useState('');
  const [pincode, setpincode] = useState('');
  const [area, setArea] = useState('');
  const [shippingRate, setShippingRate] = useState('');
  const [cartValue, setCartValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const MAX_COUNT = 1;
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const handleCheckboxChange = (pincodeid) => {
    const selectedPincodes = JSON.parse(sessionStorage.getItem('selectedPincodes')) || [];
    const index = selectedPincodes.indexOf(pincodeid);
  
    if (index !== -1) {
      selectedPincodes.splice(index, 1);
    } else {
      selectedPincodes.push(pincodeid);
    }
  
    sessionStorage.setItem('selectedPincodes', JSON.stringify(selectedPincodes));
    setIsAnyCheckboxSelected(selectedPincodes.length > 0);
    setSelectAll(data.length === selectedPincodes.length);
  };
  
  const defaultColumns = [
    {
      title: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      dataIndex: 'selectAll',
      render: (_, record) => (
        <Checkbox
          checked={isSelected(record.pincodeid)}
          onClick={() => handleCheckboxChange(record.pincodeid)}
        />
      ),
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      editable: false,
    },
    {
      title: 'State',
      dataIndex: 'state',
      editable: false,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      editable: false,
    },
  ];
  
  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: '32',
      address: `London, Park Lane no. ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const [selectedState, setSelectedState] = useState('');
  useEffect(() => {
    const fetchPincodeData = async () => {
      try {
        const token = localStorage.getItem('token');
  
        if (!token) {
          navigate('/AdminLogin');
          return;
        }
  
        if (!selectedCountry) {
          // No country selected, do nothing
          return;
        }
  
        const response = await axios.put(
          // 'http://68.178.169.226:12080/api/v1/pincode/getnonavailable',
          {
            country: selectedCountry,
            state: selectedState, // Add the selected state variable here
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 401 || response.status === 404) {
          navigate('/AdminLogin');
          return;
        }
  
        setData(response.data);
        sessionStorage.removeItem('selectedPincodes');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchPincodeData();
  }, [selectedCountry, selectedState, navigate]);
  
  // const handleRemovePincode = async () => {
  //   try{
  //     const token = localStorage.getItem('token');
  //     if (!token) {
  //       navigate('/AdminLogin');
  //       return;
  //     }
  //     const selectedPincodes = JSON.parse(sessionStorage.getItem('selectedPincodes')) || [];
  //     if (selectedPincodes.length === 0) {
  //       alert('Please Select the Pincode for removing');
  //       return;
  //     }
  //     const formData = new FormData();
  //     formData.append('pincodeid',selectedPincodes);

  //     const response = await axios.post('http://68.178.169.226:12080/api/v1/pincode/delete',
  //     formData,
  //     {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     }
  //     );
  //     console.log('Remove Pincodes',response.data);
  //     sessionStorage.removeItem('selectedPincodes');
  //   } catch(error) {
  //     console.error("Error",error);
  //   }
  // }
  const handleMakeAvailable = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/AdminLogin');
        return;
      }

      const selectedPincodes = JSON.parse(sessionStorage.getItem('selectedPincodes')) || [];

      if (selectedPincodes.length === 0) {
        // No pincodes selected, show an alert or handle accordingly
        alert('Please select pincodes before making them available.');
        return;
      }

      // Create FormData
      const formData = new FormData();
        formData.append(`pincodeid`, selectedPincodes);

      const response = await axios.post(
        'http://68.178.169.226:12080/api/v1/pincode/makeavailable',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      // Handle the API response as needed
      console.log('Make Available API Response:', response.data);

      // Clear selected pincodes from sessionStorage after making them available
      sessionStorage.removeItem('selectedPincodes');
      window.location.reload();
      // You may also want to refresh the data after making pincodes available
      // Call fetchPincodeData or any relevant function here

    } catch (error) {
      console.error('Error making pincodes available:', error);
    }
  };
  useEffect(() => {
    const fetchPincodeDataforcountry = async () => {
      try {
        const response = await axios.put("http://68.178.169.226:12080/api/v1/pincode/getnotavailablecountry", {}, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate('/AdminLogin');
          return; // Exit early to prevent further logic execution
        }

        setCountry(response.data);
        const countries = response.data.map(item => item.country);

        // Store the countries in sessionStorage
        if (countries.length > 0) {
          sessionStorage.setItem('countryData', JSON.stringify(countries));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPincodeDataforcountry();
  }, []);
 
  const handleCountryChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);
  
    try {
      const response = await axios.put(
        'http://68.178.169.226:12080/api/v1/pincode/getnotavailablestate',
        { country: selectedValue },
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }
  
      console.log('Get Not Available State API Response:', response.data);
  
      // Filter out null values and extract the 'state' property
      const states = response.data
        .filter((item) => item !== null && item.state !== null)
        .map((item) => item.state);
  
      console.log('States Data:', states);
  
      // Set the states in your component state
      setStates(states);
    } catch (error) {
      console.error('Error fetching state data:', error);
    }
  };
  const handleStateChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedState(selectedValue);
  
    try {
      const token = localStorage.getItem('token');
  
      if (!token) {
        navigate('/AdminLogin');
        return;
      }
  
      if (!selectedCountry || !selectedValue) {
        // No country or state selected, do nothing
        return;
      }
  
      const response = await axios.put(
        'http://68.178.169.226:12080/api/v1/pincode/getnotavailablepincode',
        {
          country: selectedCountry,
          state: selectedValue,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate('/AdminLogin');
        return;
      }
      console.log('Filtered Pincode Data:', response.data);
      setData(response.data);
      sessionStorage.removeItem('selectedPincodes');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
  
    // Toggle all checkboxes
    const allPincodes = data.map((record) => record.pincodeid);
    handleCheckboxChangeAll(allPincodes, checked);
  };
  
  const handleCheckboxChangeAll = (pincodeids, checked) => {
    const selectedPincodes = checked ? pincodeids : [];
    sessionStorage.setItem('selectedPincodes', JSON.stringify(selectedPincodes));
    setIsAnyCheckboxSelected(selectedPincodes.length > 0);
  };
  
  const isSelected = (pincodeid) => {
    const selectedPincodes = JSON.parse(sessionStorage.getItem('selectedPincodes')) || [];
    return selectedPincodes.includes(pincodeid);
  };
  const handleAddPincodeClick = () =>{
    setIsModalVisible(true);
  }
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };
  
    fetchCountryList();
  }, []);
  const fetchStates = async (selectedCountryforpincodeAdd) => {
    try {
      const countryString = Array.isArray(selectedCountryforpincodeAdd) ? selectedCountryforpincodeAdd[0] : selectedCountryforpincodeAdd;
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/pincode/getstate",
        {
          country: countryString,
        }
      );

      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching the list of states:", error);
    }
  };
  const handleCountryChangeforPincodeAdd = (value) => {
    setSelectedCountryforpincodeAdd(value);
    fetchStates(value); // Use the value directly instead of selectedCountryforpincodeAdd
  };
  
  
  const handleStateChangeforPincodeAdd = (value) => {
    setSelectedStateforpincodeAdd(value);
  };
  const handleAddPincode = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/AdminLogin');
            return;
        }
        const country = Array.isArray(selectedCountryforpincodeAdd) ? selectedCountryforpincodeAdd[0] : selectedCountryforpincodeAdd;
        const state = Array.isArray(selectedStateforpincodeAdd) ? selectedStateforpincodeAdd[0] : selectedStateforpincodeAdd;
        const requestData = {
            country: country,
            state: state,
            pincode: pincode,
            area: area,
            shippingrate: shippingRate,
            cartvalue: cartValue,
            available:isChecked,
        };

        const response = await axios.post(
            'http://68.178.169.226:12080/api/v1/pincode/add',
            requestData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        console.log('Add Pincode API Response:', response.data);
        // Optionally, handle success response, close modal, and update data
if(response.data.status === 'Error'){
  notification.error({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
}else {
  notification.success({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
  setIsModalVisible(false);
}
    } catch (error) {
        console.error('Error adding pincode:', error);
        // Optionally, handle error response
    }
};

  return (
    <div> 
        <div className="wrapper">
        <SideBar />
        <div class="ec-page-wrapper">
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>Show All Pincodes</h1>
            <select onChange={handleCountryChange} value={selectedCountry} defaultValue="" >
                <option value="" disabled hidden>Select Country</option>
      {country.map((countryObj, index) => (
        <option key={index} value={countryObj.country}>
          {countryObj.country}
        </option>
      ))}
    </select>
    <select style={{ width: "150px" }} onChange={handleStateChange} value={selectedState}>
                <option value="" disabled hidden>Select State</option>
  {states !== null ? (
    states.map((state, index) => (
      <option key={index} value={state}>
        {state}
      </option>
    ))
  ) : (
    <option value="">Loading...</option>
  )}
</select>

            <p className="breadcrumbs">
           {isAnyCheckboxSelected && (
            <>
          <button className="btn btn-primary" onClick={handleMakeAvailable}>Make Available</button>
          {/* <button className="btn btn-primary" onClick={handleRemovePincode}>Remove Pincode</button> */}
          </>
        )}

             
            </p>
            <p className="breadcrumbs">
          <button className="btn btn-info" onClick={handleAddPincodeClick} >Add New Pincode</button>
            </p>
          </div>
  <Table
    components={components}
    rowClassName={() => 'editable-row'}
    bordered
    dataSource={data}
    columns={columns}
  />
</div>
    </div>
    </div>
    </div>
    
      <Modal
      title="Add New Pincode"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleAddPincode}>
          Add 
        </Button>,
      ]}
      >
         <div>
          <label>Country:</label>
          <Select
          mode="tags"
          maxCount={MAX_COUNT}
  value={selectedCountryforpincodeAdd}
  onChange={handleCountryChangeforPincodeAdd}
  placeholder="Select country"
  style={{ width: '100%' }}
>
  {countryList.map((country) => (
    <Option key={country.country} value={country.country}>
      {country.country}
    </Option>
  ))}
</Select>
        </div>
        <div>
          <label>State:</label>
          <Select
          mode="tags"
          maxCount={MAX_COUNT}
  value={selectedStateforpincodeAdd}
  onChange={handleStateChangeforPincodeAdd}
  placeholder="Select state"
  style={{ width: '100%' }}
>
  {stateList.map((state) => (
    <Option key={state.state} value={state.state}>
      {state.state}
    </Option>
  ))}
</Select>
        </div>
        <div>
          <label>Pincode:</label>
          <InputNumber
            value={pincode}
            onChange={(value) => setpincode(value)}
            style={{width:"100%"}}
          />
        </div>
        <div>
          <label>Area:</label>
          <Input
            value={area}
            onChange={(e) => setArea(e.target.value)}
          />
        </div>
        <div>
          <label>Shipping Rate:</label>
          <InputNumber
            value={shippingRate}
            onChange={(value) => setShippingRate(value)}
            style={{width:"100%"}}
          />
        </div>
        <div>
          <label>Cart Value:</label>
          <InputNumber
            value={cartValue}
            onChange={(value) => setCartValue(value)}
            style={{width:"100%"}}
          />
        </div>
        <div>
          <label>Available:</label>
          <Checkbox value="yes" required onChange={(e) => setIsChecked(e.target.checked)}>Yes</Checkbox>
          </div>
      </Modal>
    </div>
  );
};
export default ShowPincodes;