import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const EditBannerPopup = ({ banner, visible, onCancel, onSave }) => {
  const token = localStorage.getItem('token');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [uploadedOrganiserId, setUploadedOrganiserId] = useState(null);
  useEffect(() => {
    if (visible) {
      form.setFieldsValue(banner);  // Assuming iconimage is part of the category data
    }
  }, [visible, banner, form]);
  const isValidURL = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/common/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/AdminLogin');
        return;
      }

      if (response.status === 200) {
        const uploadedId = JSON.stringfy(response.data).organiserId;
        setUploadedOrganiserId(uploadedId);
        onSuccess("File uploaded");
      } else {
        onError("File upload failed");
      }
    } catch (error) {
      onError("File upload failed");
    }
  };
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (values.url && !isValidURL(values.url)) {
        // Display an error message for invalid URL
        alert("Invalid URL. Please enter a valid URL.");
        return;
      }
      // Create a FormData object
      const formData = new FormData();

      // Append the values to the FormData object
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('section', values.section);
      formData.append('position', values.position);
      formData.append('file',uploadedOrganiserId)
      // Append the uploaded file to the FormData object (if any)
      // if (values.file && values.file.length > 0) {
      //   formData.append('file', values.file[0].originFileObj);
      //   handleFileUpload({ file: values.file[0].originFileObj });
      // }

      // Call the onSave callback with the FormData object
      onSave({ ...values, bannerid: banner.bannerid, uploadedOrganiserId: uploadedOrganiserId });

      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      title="Edit Banner"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSave}
      okText="Save"
      cancelText="Cancel"
    >
      <Form form={form} initialValues={banner}>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="URL" name="url">
          <Input />
        </Form.Item>
        <Form.Item label="Position" name="position">
          <Input />
        </Form.Item>
        <Form.Item label="Section" name="section">
        <select name="Section">
  
        <option value="1">Main Banner( Section 1)</option>
  <option value="2">Section 2</option>
  <option value="3">Section 3</option>
  <option value="4">Section 4</option>
  <option value="5">Section 5</option>
  <option value="6">Section 6</option>
</select>
        </Form.Item>
        {/* <Form.Item
>
    <img key={banner.bannerid} src={banner.file} alt={`banner-${banner.bannerid}`} style={{ width: '100px', marginRight: '10px' }} />
</Form.Item>
        <Form.Item label="Upload Image" name="file">
          <Upload customRequest={handleFileUpload}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default EditBannerPopup;
