import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import { Select, Form, Upload, Button,Modal,notification } from "antd";
import Header from './Header';
import EditBannerPopup from './EditBannerPopup';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
  { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];

const Banner = () => {
  const token = localStorage.getItem('token');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [url, setURL] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fulldescription, setFulldescription] = useState('');
  const [producttag, setProducttag] = useState('');
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [file, setFile] = useState([]);
  const [section, setSection] =useState('');
  const [position, setPosition] =useState('');

  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
  const [selectedBanner, setSelectedBanner] = useState(null); // State to store the selected banner for editing
  const [showEditPopup, setShowEditPopup] = useState(false); // State to manage the edit popup visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBannerForDeletion, setSelectedBannerForDeletion] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // Function to open the edit popup
  const handleEditBanner = (banner) => {
    setSelectedBanner(banner);
    setShowEditPopup(true);
  };

  // Function to close the edit popup
  const handleCloseEditPopup = () => {
    setSelectedBanner(null);
    setShowEditPopup(false);
  };

  // Function to open the edit modal
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };

 // Function to save the edited data
 const handleSaveEdit = async (editedData) => {
  try {
    // Create a new FormData object
    const formData = new FormData();

    // Append the bannerid to the FormData
    formData.append('id', selectedBanner.bannerid);

    // Append other fields to the FormData
    formData.append('url', editedData.url);
    formData.append('title', editedData.title);
    formData.append('description', editedData.description);
    formData.append('section', editedData.section);
    formData.append('position', editedData.position);
    formData.append('file' , editedData.file); 
    // Make an API call to update the banner with the edited data
    const response = await axios.post(
      `http://68.178.169.226:12080/api/v1/banners/edit`,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
        },
      }
    );

    if (response.status === 401 || response.status === 404) {
      // Redirect to the login page for 401 or 404 errors
      navigate('/AdminLogin');
      return;
    }

    if (response.data) {
      // Update the banner data in the state with the edited data
      setData((prevData) =>
        prevData.map((banner) =>
          banner.bannerid === selectedBanner.bannerid
            ? { ...banner, ...editedData }
            : banner
        )
      );
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      // Close the edit popup
      handleCloseEditPopup();
    }
  } catch (error) {
    console.error('Error updating banner:', error);
  }
};




  useEffect(() => {
    if (!token) {
      // Redirect to the login page if the token is not present
      navigate('/AdminLogin');
      return;
    }
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axios.put("http://68.178.169.226:12080/api/v1/banners/get", {}, {

        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isValidURL = (url) => {
    // Regular expression for a simple URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const token = localStorage.getItem('token');
    if (!section) {
      // Display an error message or handle the validation as needed
      alert("Section is required. Please select a section.");
      return;
    }
    if (url && !isValidURL(url)) {
      // Display an error message for invalid URL
      alert("Invalid URL. Please enter a valid URL.");
      return;
    }
    if (file.length === 0) {
      alert("Please upload the banner image");
      return;
    }
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("url", url);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("section", section);
    formData.append("position", position);

    try {
      setLoading(false);
      setUploading(true);
      // TODO: Replace with actual API call
      const response = await fetch("http://68.178.169.226:12080/api/v1/banners/add", {
        method: "POST",
        body:
          formData,
        headers: {
          'Authorization': 'Bearer ' + token,
          //   'Content-Type': 'application/json',
        },
      });

      //   if (response.status === 401 || response.status === 404) {
      // 	// Redirect to the login page for 401 or 404 errors
      // 	navigate('/AdminLogin');
      // 	return; // Exit early to prevent further logic execution
      //   }

      if (response.ok) {
        const data = await response.json();
        notification.success({
          message: data.description,
          duration: 3, // Duration in seconds
        });
        resetFormFields();
        fetchData();
        const token = data.token;

        console.log("POST request successful");
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData([]); // If no data is received, set an empty array
        }
        setLoading(false);

        // window.location.reload(); // Refresh the page
        if (data.token) {
          localStorage.setItem('token', token);
        }
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
        notification.error({
          message: response.data.description,
          duration: 3, // Duration in seconds
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }finally {
      setUploading(false); // Reset uploading state regardless of success or failure
    }
  };

  const handleFileUpload = (options) => {
    const { file } = options; // Extract the selected file from options
    setFile(file); // Store the selected file in the state
    setUploadSuccess(true);
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
  });

  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

//Delete API

// const handleDeleteCategory = async (bannerid) => {
//   try {
//     setLoading(true);
  
//     // Make an API request to delete the category
//     const response = await axios.post(
//     `http://68.178.169.226:12080/api/v1/banners/delete`,
//     {
//       bannerid: bannerid, // Pass the categoryId as a payload
//     },
//     {
//       headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('token'),
//       'Content-Type': 'application/json',
//       },
//     }
//     );
//     if (response.status === 200) {
//     // Refresh the page
//     window.location.reload();
//     } else {
//     console.error('Failed to delete Common Info');
//     }
  
//     // Rest of the code remains the same
//     // ...
//   } catch (error) {
//     console.error('Error deleting Common Info:', error);
//     setLoading(false);
//   }
//   };
const handleDeleteCategory = async (bannerid) => {
  try {
    setLoading(true);

    // Set the selected category for deletion
    const selectedBanner = data.find((category) => category.bannerid === bannerid);
    setSelectedBannerForDeletion(selectedBanner);

    // Show the delete confirmation modal
    setShowDeleteModal(true);
  } catch (error) {
    console.error('Error preparing delete:', error);
    setLoading(false);
  }
};
const handleDeleteCategoryConfirmed = async () => {
  try {
    // Make an API request to delete the category
    const response = await axios.post(
      `http://68.178.169.226:12080/api/v1/banners/delete`,
      {
        bannerid: selectedBannerForDeletion.bannerid,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      notification.success({
        message: 'Banner deleted successfully',
        duration: 3, // Duration in seconds
      });
      // Refresh the page or update the banner list
      fetchData();
      // window.location.reload();
    } else {
      console.error('Failed to delete banner');
    }
  } catch (error) {
    console.error('Error deleting banner:', error);
  } finally {
    // Clear the selected category and hide the modal
    setSelectedBannerForDeletion(null);
    setShowDeleteModal(false);
  }
};
const sectionMapping = {
  1: "Main Banner",
  2: "Section 2",
  3: "Under Category Bar 1",
  4: "Under Category Bar 2",
  5: "Flash Sale",
  6: "Limited Time Offers",
};
const resetFormFields = () => {
  setURL('');
  setTitle('');
  setDescription('');
  setSection('');
  setPosition('');
  setFile(null); // Reset uploaded files, if needed
  setFileList([]);
  setUploading(false);
  setUploadSuccess('');
};
const handleImageClick = (image) => {
  setSelectedImage(image);
};

// Function to close the image modal
const handleCloseImageModal = () => {
  setSelectedImage(null);
};

  return (
    <div className="wrapper">

      {/* <!-- LEFT MAIN SIDEBAR --> */}
      <SideBar />
      <div class="ec-page-wrapper">
      {/* <!-- Header --> */}
      <Header />

      {/* <!-- CONTENT WRAPPER --> */}
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>Banner</h1>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="ec-cat-list card card-default mb-24px">
                <div className="card-body">
                  <div className="ec-cat-form">
                    <h4>Add New Banner</h4>

                    <form
                      onSubmit={onFinish}
                    >

                      <div className="col-md-12">
                        <label className="form-label" name="uploadfile">Upload Images</label>
                        <Form.Item
                          name="uploadfile"
                        >
                          <Upload
                            customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
                            showUploadList={false} 
                            required
                          >
                            <Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}> {uploading ? 'Uploading...' : 'Click to Upload'}</Button>
                          </Upload>
                        </Form.Item>
                        {uploadSuccess && (
  <div style={{ color: 'green', marginTop: '5px' }}>
    Image loaded successfully!
  </div>
)}
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" name="title">URL</label>
                        <input id="title" name="title" className="form-control here set-slug" type="text" value={url} onChange={(e) => setURL(e.target.value)} autoComplete="off" />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" name="title">Title</label>
                        <input id="title" name="title" className="form-control here set-slug" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off"  />
                      </div>
                      <div className="col-md-12">
                        <label for="description" className="col-12 col-form-label">Description</label>
                        <div className="col-12">
                          <input id="description" name="description" className="form-control here set-slug" type="text" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off"  />
                        </div>
                      </div>
                      <div class="col-md-12"><label for="description" class="col-12 col-form-label">Section</label><div class="col-12">
                      <select name="Section" id="section-Banner" onChange={(e) => setSection(e.target.value)}  value={section || ''}  required >
                      <option value="">Select Section</option>
  <option value="1">Main Banner(Section 1)</option>
  <option value="2">Section 2</option>
  <option value="3">Section 3</option>
  <option value="4">Section 4</option>
  <option value="5">Section 5</option>
  <option value="6">Section 6</option>
</select>
</div></div> 
<div className="col-md-12">
                        <label for="description" className="col-12 col-form-label">Position</label>
                        <div className="col-12">
                          <input id="description" name="description" className="form-control here set-slug" type="number" value={position} onChange={(e) => setPosition(e.target.value)} autoComplete="off"  />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12">
              <div className="ec-cat-list card card-default">
                <div className="card-body">
                  <div className="table-responsive">
                    <table id="responsive-data-table" className="table">
                      <thead>
                        <tr>
                          {/* <th>Thumb</th> */}
                          <th>Image</th>
                          <th>URL</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Section</th>
                          <th>Position</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((category) => (
                          <tr key={category.bannerid}>
                            <td><img src={category.file} alt="category.bannerid" width="100"  onClick={() => handleImageClick(category.file)}
                      style={{ cursor: 'pointer' }}/></td>
                            <td>{category.url != null ? category.url : "javascript:void(0)"}</td>
                            <td>{category.title}</td>
                            <td>{category.description}</td>
                            <td>{sectionMapping[category.section]}</td>
                            <td>{category.position}</td>
                            <td>
                              <div className="btn-group">
                                <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleEditBanner(category); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleDeleteCategory(category.bannerid); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Delete
                                </a>
                                {/* Add your delete logic here */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showDeleteModal}
        title="Confirm Deletion"
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteCategoryConfirmed}
        okText="Delete"
      >
        <p>Are you sure you want to delete this banner?</p>
      </Modal>
      <Modal
        visible={!!selectedImage}
        onCancel={handleCloseImageModal}
        footer={null}
        width={1200}
      >
        <img src={selectedImage} alt="Selected Banner" style={{ width: '100%' }} />
      </Modal>

      {selectedBanner && (
        <EditBannerPopup
          banner={selectedBanner}
          visible={showEditPopup}
          onCancel={handleCloseEditPopup}
          onSave={handleSaveEdit}
        />
      )}

    </div>
    </div>
  );
}

export default Banner;
