// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../src/editCnfirmation/ConfirmationModal";

const EditPlanModal = ({
  category,
  show,
  onHide,
  onSave,
  isLoadingEdit,
  editChitDetails,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Define state variables for form fields
  const [planname, setCategoryname] = useState(category.planname);
  const [amount, setSlug] = useState(category.amount);
  const [noofmonth, setSortdescription] = useState(category.noofmonth);
  const [noofcategories, setFulldescription] = useState(category.noofcategories);
  const [noofproducts, setProducttag] = useState(category.noofproducts);
  const [sellerplanrank, setsellerplanrank] = useState(category.sellerplanrank);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };


  

  // const handleSaveChanges = async () => {
  //   // Create an object with the updated values
  //   const updatedCategory = {
  //     categoryid: category.categoryid, // Include the category ID for identification
  //     categoryname,
  //     slug,
  //     sortdescription,
  //     fulldescription,
  //     producttag,
  //     categorylocation,
  //   };

  //   try {
  //     setLoading(true);
  //     if(fileid) {
  //       const formData = new FormData();
  //       formData.append("file",fileid);

  //       const uploadResponse = await axios.post('http://68.178.169.226:12080/api/v1/fileupload/file',
  //       formData,
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + localStorage.getItem('token'),
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //       );
  //       if(uploadResponse.status === 401 || uploadResponse.status === 404){
  //       navigate('/AdminLogin');
  //     return;
  //       }
  //       updatedCategory.fileid = uploadResponse.data.organiserId;

  //     }
  //     // Make an API request to update the category
  //     const response = await axios.post(
  //       'http://68.178.169.226:12080/api/v1/category/edit',
  //       updatedCategory,
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + localStorage.getItem('token'),
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.status === 401 || response.status === 404) {
  //       // Redirect to the login page for 401 or 404 errors
  //       navigate('/AdminLogin');
  //       return;
  //     }

  //     if (response.data.success) {
  //       console.log('Category updated successfully');
  //       onSave(updatedCategory); // Notify the parent component about the update
  //       onHide(); // Close the modal
  //     } else {
  //       console.error('Failed to update category');
  //     }

  //     setLoading(false);
  //     window.location.reload(); // Refresh the page
  //   } catch (error) {
  //     console.error('Error updating category:', error);
  //     setLoading(false);
  //   }
  // };
  const handleSaveChanges = async () => {
    const updatedCategory = {
        sellerplanid: category.sellerplanid, // Include the category ID for identification
        planname,
        amount,
        noofmonth,
        noofcategories,
        noofproducts,
        sellerplanrank,
    };
  
    try {
      setLoading(true);
  
      // Make an API request to update the category without uploading the image
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/sellerplan/addedit",
        updatedCategory,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        // navigate("/AdminLogin");
        return;
      }
  
      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(updatedCategory); // Notify the parent component about the update
        onHide(); // Close the modal
      } else {
        console.error("Failed to update category");
      }
  
      setLoading(false);
    //   window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };
  
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    // Proceed with saving changes
    handleSaveChanges();
  };  

  return (
    <Modal show={show} onHide={onHide}>
      {/* Modal content */}
      <Modal.Header closeButton>
        <Modal.Title>Edit Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="categoryname">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              type="text"
              name="categoryname"
              value={planname}
              onChange={(e) => setCategoryname(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="slug">
            <Form.Label>amount</Form.Label>
            <Form.Control
              type="text"
              name="slug"
              value={amount}
              onChange={(e) => setSlug(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="sortdescription">
            <Form.Label>noofmonth</Form.Label>
            <Form.Control
             type="text"
              name="sortdescription"
              value={noofmonth}
              onChange={(e) => setSortdescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="fulldescription">
            <Form.Label>noofcategories</Form.Label>
            <Form.Control
              type="text"
              name="fulldescription"
              value={noofcategories}
              onChange={(e) => setFulldescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="producttag">
            <Form.Label>noofproducts</Form.Label>
            <Form.Control
              type="text"
              name="producttag"
              value={noofproducts}
              onChange={(e) => setProducttag(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="sellerplanrank">
            <Form.Label>sellerplanrank</Form.Label>
            <Form.Control
              type="text"
              name="producttag"
              value={sellerplanrank}
              onChange={(e) => setsellerplanrank(e.target.value)}
            />
          </Form.Group>
          

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleShowConfirmationModal} disabled={loading}>
          Save Changes
        </Button>
        
      </Modal.Footer>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Modal>
  );
};

export default EditPlanModal;
