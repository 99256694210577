import React, { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import "../../style.scss";
import "../../riot_admin.scss";
import SideBar from "../../vendor/sidebar/index";
import VendorHeader from "../../../src/vendor/header";
import axios from "axios";
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logoShopeasey from "../../../src/images/megashop.png"

const Paymentreceipt = () => {
    const location = useLocation();
    const [logoUrl, setLogoUrl] = useState('');
    const [amountOfSeller, setAmountOfSeller] = useState(''); 
    const [redirectStatus, setRedirectStatus] = useState("");
    const [plancommence, setPlancommence] = useState("");
    const [planexpiry, setPlanexpiry] = useState("");
    const [numberOfMonths1, setNumberOfMonths] = useState("");
    const [clientId, setClientId] = useState(null);
    const { paymentDetails } = location.state || {};
    const pdfRef = useRef();
    const [billFrom, setBillFrom] = useState({
        companyname: "",
        address: "",
        phone: "",
        email: "",
    });
    const [billTo, setBillTo] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
    });
    const formattedDate = moment(paymentDetails?.createDate).format('MMMM DD, YYYY');

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(
                    'http://68.178.169.226:12080/api/v1/login/getuserdetails',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                        },
                    }
                );

                if (response.ok) {
                    const responseData = await response.json();
                    setClientId(responseData.id);
                    const geByIdResponse = await fetch(
                        'http://68.178.169.226:12080/api/v1/seller/getbyid',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                            },
                            body: JSON.stringify({
                                sellerid: responseData.id,
                            }),
                        }
                    );
                    if (geByIdResponse.ok) {
                        const geByIdData = await geByIdResponse.json();
                        setBillTo({
                            firstname: geByIdData.firstname || "",
                            lastname: geByIdData.lastname || "",
                            businessadress: geByIdData.businessadress || "",
                            mobile: geByIdData.mobile ? geByIdData.mobile : "",
                            email: geByIdData.email || "",
                            sellerid: geByIdData.sellerid || "",
                            planname: geByIdData.planname || "",
                            noofcategories: geByIdData.noofcategories || "",
                            noofproducts: geByIdData.noofproducts || "",
                            activated: geByIdData.activated || "",
                            gst: geByIdData.gst || "",
                        });
                        setPlancommence(geByIdData.plancommence);
                        setPlanexpiry(geByIdData.planexpiry);
                        const startMoment  = moment(plancommence);
                        const endMoment = moment(planexpiry);
                        const numberOfMonths = endMoment.diff(startMoment, 'months');
                        setNumberOfMonths(numberOfMonths);
                    } else {
                        console.error('Error fetching seller details by id');
                    }
                    const sellerAmountResponse = await fetch(
                        'http://68.178.169.226:12080/api/v1/sellerpayment/get',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                            },
                            body: JSON.stringify({
                                sellerid: responseData.id,
                            }),
                        }
                    );
                    if (sellerAmountResponse.ok) {
                        const responseData = await sellerAmountResponse.json();
                        let totalAmount = 0;
                        let redirectStatus;
    
    responseData.forEach(payment => {
        totalAmount += parseFloat(payment.amount);
        redirectStatus = payment.paymentinfo.redirectStatus;
    });

    setAmountOfSeller(totalAmount);
    setRedirectStatus(redirectStatus);
                    } else {
                        console.error('Failed to fetch payment details for the seller');
                    }
                } else {
                    console.error('Error fetching client details');
                }
            } catch (error) {
                console.error("Error checking authentication:", error);
            }
        };

        fetchClientDetails();
    }, []);
    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await fetch('http://68.178.169.226:12080/api/v1/logo/getall',{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setBillFrom({
                        companyname: data.companyname || "",
                        address: data.address || "",
                        phone: data.phone ? data.phone[0] : "",
                        email: data.email || "",
                        gstnumber: data.gstnumber || "",
                        ssm: data.ssm || "",
                    });
                    setLogoUrl(data.logo);
                } else {
                    console.error('Failed to fetch logo');
                }
            } catch (error) {
                console.error('Error fetching logo:', error);
            }
        };

        fetchLogo();
    }, []);

    const handleDownload1 = () => {
        const contentClone = document.documentElement.cloneNode(true);
        const downloadButton = contentClone.querySelector('button');
        if (downloadButton) {
            downloadButton.remove();
        }
        const image = document.createElement('img');
        image.src = logoUrl;
        image.onload = () => {
            contentClone.appendChild(image);
            html2pdf(contentClone, {
                margin: 10,
                filename: 'payment_receipt.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            });
        };
    };
    const handleDownload= async () => {
        try {
            const content = pdfRef.current; // Target only the content to be included in the PDF
            const canvas = await html2canvas(content, { useCORS: true });
    
            const imgData = canvas.toDataURL('image/png');
    
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });
    
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('payment_receipt.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    return (
        <div className="wrapper">
            <div className="ec-content-wrapper payment-recept-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <button className="" style={{height:"50px",marginTop:"50px"}} onClick={handleDownload}>Download Page</button>
                        </div>
                        <div className="mt-4 trk-order justify-content-center" ref={pdfRef}>
                            <div className="text-lg bg-dark payment-receipt-header">
                            <div className="col-md-4">
                                    <img src={logoShopeasey} alt="Logo" width='200px'/>
                                </div> 
                                <div className="row">
                                    <div className="col-md-6">
                                Invoice <br/>
                                        Receipt Number :SE001<br/>
                                        Date: {formattedDate}
                                </div>
                                <div className="col-md-6">
                                    GST NO : {billFrom?.gstnumber} <br/>
                                    MY SSM NO : {billFrom?.ssm}
                                </div>
                                </div>
                            </div>
                            <div className="row payment-receipt-header mb-3">
                                <div className="col-md-6">
                                <strong>BILL FROM</strong> <br/>
                                    <div>
                                        <strong>{billFrom.companyname}</strong><br/>
                                        {billFrom.address}<br/>
                                        Phone: {billFrom.phone}<br/>
                                        Email: {billFrom.email}<br/>
                                        {/* GST:{billFrom?.gstnumber} */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <strong>BILL TO</strong> <br/>
                                    <div>
                                        <strong>{billTo.firstname} {billTo.lastname}</strong><br/>
                                        {billTo.businessadress}<br/>
                                        Phone: {billTo.mobile}<br/>
                                        Email: {billTo.email}<br/>
                                        GST:{billTo?.gst}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 seller-pay-details">
                                <div className="col-md-6">
                                <strong><span className="text-medium">SELLER ID : </span> {billTo.sellerid}<br/></strong>
                                </div>
                                <div className="col-md-6 text-end">
                                <strong><span className="text-center">STATUS : </span>{redirectStatus}</strong>
                                </div>
                            </div> 
                            <div className="col-12">
                                <div className="ec-odr-dtl  card-default">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <table className="table table-striped o-tbl custom-tableforplan">
                                                        <thead className="paymnt-recept-th">
                                                            <tr className="payment-recepit-table-head">
                                                                <td className="col-3">TITLE</td>
                                                                <td className="col-md-6">DESCRIPTION</td>
                                                                <td className="col-md-3">AMOUNT</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="custom-tableforplan-tbody">
                                                            <tr className="">
                                                                <td className="col-3">Registration fee</td>
                                                                <td className="col-6">
                                                                    {numberOfMonths1} month validity for  
                                                                    {billTo.noofcategories} number of categories &
                                                                   {billTo.noofproducts}number of products
                                                                </td>
                                                                <td className="col-3"><strong>RS {amountOfSeller}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table> 
                                                    <div className="outer-table-container-test">
                                                    <div className="outer-table-container mb-3">
  <table className="table inner-table">
    <tbody>
      <tr>
        <td className="col-3"></td>
        <td className="col-6 text-center">Sub Total : </td>
        <td className="col-3 text-center">RS {amountOfSeller}</td>
      </tr>
      <tr>
        <td className="col-3"></td>
        <td className="col-6 text-center">Taxes : </td>
        <td className="col-3 text-center">N/A</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<div className="row  custom-table-total-top">
<div className="mt-3 col-md-6">
<table className="table custom-table-total">
  <tbody>
    <tr>
      <td className="col-4"><strong>Total</strong></td>
      <td className="col-4"><strong>:</strong></td>
      <td className="col-4"><strong>{amountOfSeller}</strong></td>
    </tr>
  </tbody>
</table></div>
</div>
<div className="row mt-4 termsapply-thankyou">
    <div className="col-md-8">
        <h3>TERMS AND CONDITIONS APPLY</h3>
        <p>Your {billTo.noofcategories} category plan started from on the date mobile app launch until 6 month date
        You may cancel your plan at any time.No refunded.</p>
    </div>
    <div className="col-md-4 text-end">
        <h3>Thank You!</h3>
        <p>for joining with us</p>
    </div>
</div>
<div className="row text-center">
    <div className="col-md-12">
        <p className="">Note: This receipt is computer generated and no signature is required.</p>
    </div>
</div>

                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default Paymentreceipt;
