// Routes.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './AdminLogin'; 
import MainCategory from "./MainCategory";
import SubCategory from "./SubCategory";
import ProductDetail from './ProductDetail';
import ListProduct from './ListProduct';
import ProductGrid from './ProductGrid';
import ProductAdd from './ProductAdd1_copy';
import Quantity_update from './quantity_update';
import DuplicateProduct from './DuplicateProduct';
import ProductEdit from '../src/ProductEdit';
import SubSubCategory from './SubsubCategory';
import Banner from './Banner';
import Offer from './offer';
import CommonInfo from "./CommonInfo";
import ClientDetails from "./ClientDetails";
import CompanyInfo from "./CompanyInfo";
import TermsConditions from "./TermsConditions";
import NewOrder from './order/NewOrder';
import OrderDetail from './order/OrderDetail';
import EditTerms from "../src/otherpages/EditTerms";
import AboutUs from "./AboutUs";
import EditAbout from "../src/otherpages/EditAbout";
import PrivacyPolicy from "../src/otherpages/Privacy";
import EditPrivacy from "../src/otherpages/EditPrivacy";
import CustomerService from '../src/otherpages/customerservice';
import ContactUS from '../src/otherpages/contactUs';
import EditCustomer from "../src/otherpages/EditCustomer";
import EditContactUS from "../src/otherpages/EditContactus";
import AvailablePincodes from "../src/pincodes/index";
import ShowPincodes from "../src/pincodes/showpincodes";
import DeliveryCharges from "../src/deliverycharges/index";
import DeleteCategoryModal from "../src/Delete/DeleteCategoryModal";
import Seller from "../src/seller/index";
import Stage1 from "../src/seller/stage1";
import Stage2  from '../src/seller/stage2';
import Stage3  from '../src/seller/stage3';
import Stripe from "../src/stripe/stripe";
import CheckoutForm from "../src/stripe/CheckoutForm";
import ProductAddforVendor from "../src/vendor/product/addproduct";
import ListProductforVendor from "../src/vendor/product/listProduct";
import NewOrderforVendor from "../src/vendor/product/order/index";
import PaymentSuccess from '../src/stripe/paymentsucess';
import VendorHeader from '../src/vendor/header';
import VendorList from '../src/vendorList/index';
import RestockModal from "../src/RestockModal";
import ForgotPassword from '../src/forgotPassword/index';
import VendorProfile from "../src/vendor/profile/index";
import Paymentreceipt from "../src/vendor/profile/paymentreceipt";
import SellerPlan from "./SellerPlan";
import Repayment from './seller/repayment';
import AllPayments from './vendor/profile/allpayments';
import CurrencyPage from '../src/currency/index';
import DuplicateProductAdd from './DuplicateProductAdd';
import TermsConditionsforSeller from '../src/seller/termsconditions/index';
import EditTermsforSeller from '../src/seller/termsconditions/EditTermsforSeller';
import AgreedProcessPayment from '../src/seller/termsconditions/agrredProcess/index';
import TermforSeller from '../src/otherpages/termscondiotionseller';
import AgreedProcessPaymentforadd from '../src/otherpages/AgreedProcessPaymentforadd';
import EditAgreeProcess from '../src/otherpages/EditAgreeProcessPay';
import AfterRegister from '../src/seller/afterRegistrSuceess';
import AfterRegisterFailed from '../src/seller/afterFAiled';
import Coupon from "../src/coupon/index";
import ListCoupons from "../src/coupon/listCoupon";
import SocialMediaIcons from "../src/socialMediaIcons/index";
import EditSocialMediaIcons from "../src/socialMediaIcons/EditSocialMediaIcons";
import DashBoard from '../src/dashboard/index';
import SellerDashBoard from '../src/dashboard/sellerDashbooard';
import SocialMediaIconsforSeller from "../src/vendor/socialmedia/index";
import EditSocialMediaIconsforSeller from "../src/vendor/socialmedia/editSocialMediaiCon";
export default function AppRoutes() {
  return (
    <Router>
      <Routes>
      <Route index element={<AdminLogin />} />
      <Route path="/AdminLogin" element={<AdminLogin />} />
      <Route path="/main-banner" element={<Banner />} />
      <Route path="/main-offer" element={<Offer />} />
        <Route path="/main-category" element={<MainCategory />} />
        <Route path="/sub-category" element={<SubCategory />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/product-list" element={<ListProduct />} />
        <Route path="/product-grid" element={<ProductGrid />} />
        <Route path="/product-add" element={<ProductAdd />} />
        <Route path="/product-edit" element={<ProductEdit />} />
        <Route path="/quantity_update" element={<Quantity_update />} />
        <Route path="/subsub-category" element={<SubSubCategory />} />
        <Route path="/main-common" element={<CommonInfo />} />
        <Route path="/main-company" element={<CompanyInfo />} />
        <Route path="/clientDetails" element={<ClientDetails />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/terms-conditions-seller" element={<TermforSeller />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/new-order" element={<NewOrder />} />
        <Route path="/order-detail" element={<OrderDetail />} />
        <Route path="/order-detail/:orderId" element={<OrderDetail />} />
        <Route path="/EditTerms" element={<EditTerms />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/EditAbout" element={<EditAbout />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/EditPrivacy" element={<EditPrivacy />} />
        <Route path="customer-service" element={<CustomerService />} />
        <Route path="/EditCustomer" element={<EditCustomer />} />
        <Route path="/EditContactUS" element={<EditContactUS />} />
        <Route path="/agreed-process" element={<AgreedProcessPaymentforadd />} />
        <Route path="/main-pincodes" element={<AvailablePincodes />} />
        <Route path="/main-pincode" element={<ShowPincodes />} />
        <Route path="/delivery-charges" element={<DeliveryCharges />} />
        <Route path="/DeleteCategoryModal" element={<DeleteCategoryModal />} />
        <Route path="/Seller" element={<Seller />} />
        <Route path="/Stage1" element={<Stage1 />} />
        <Route path="/Stage2" element={<Stage2 />} />
        <Route path="/Stage3" element={<Stage3 />} />
        <Route path="/TermsConditionsforSeller" element={<TermsConditionsforSeller />} />
        <Route path="/EditTermsforSeller" element={<EditTermsforSeller />} />
        <Route path="/AgreedProcessPayment" element={<AgreedProcessPayment />} />
        <Route path="/Stripe" element={<Stripe />} />
        <Route path="/CheckoutForm" element={<CheckoutForm />} />
        <Route path="/ProductAddforVendor" element={<ProductAddforVendor />} />
        <Route path="/ListProductforVendor" element={<ListProductforVendor />} />
        <Route path="/NewOrderforVendor" element={<NewOrderforVendor />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/VendorHeader" element={<VendorHeader />} />
        <Route path="/main-Vendors" element={<VendorList />} />
        <Route path="/RestockModal" element={<RestockModal />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/VendorProfile" element={<VendorProfile />} />
        <Route path="/Paymentreceipt" element={<Paymentreceipt />} />
        
        <Route path="/main-sellerPlan" element={<SellerPlan />} />
        <Route path="/Repayment" element={<Repayment />} />
        <Route path="/AllPayments" element={<AllPayments />} />
        <Route path="/CurrencyPage" element={<CurrencyPage />} />
        <Route path="/DuplicateProduct" element={<DuplicateProduct />} />
        <Route path="/DuplicateProductAdd" element={<DuplicateProductAdd />} />
        <Route path="/EditAgreeProcess" element={<EditAgreeProcess />} />
        <Route path="/AfterRegister" element={<AfterRegister />} />
        <Route path="/AfterRegisterFailed" element={<AfterRegisterFailed />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/ListCoupons" element={<ListCoupons />} />
        <Route path="/main-Icons" element={<SocialMediaIcons />} />
        <Route path="/EditSocialMediaIcons" element={<EditSocialMediaIcons />} />
        <Route path="/DashBoard" element={<DashBoard />} />
        <Route path="/SellerDashBoard" element={<SellerDashBoard />} />
        <Route path="/SocialMediaIconsforSeller" element={<SocialMediaIconsforSeller />} />
        <Route path="/EditSocialMediaIconsforSeller" element={<EditSocialMediaIconsforSeller />} />
      </Routes>
    </Router>
  );
}


