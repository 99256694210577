import React, { useState, useEffect } from 'react';
import '../style.scss';
import '../riot_admin.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { Select } from 'antd';
import LoginHeader from '../componant/LoginHeader';
import "../App.css";

const Seller = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [showOtpFieldsverified, setShowOtpFieldsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [resendOtpStatus, setResendOtpStatus] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [registrationError, setRegistrationError] = useState('');
  const [mobileError, setMobileError] = useState("");
  const [emailAvailabilityMessage, setEmailAvailabilityMessage] = useState('');
  const [mobileAvailabilityMessage, setMobileAvailabilityMessage] = useState('');
  const [showOtpFieldsformobile, setShowOtpFieldsformobile] = useState(false);
  const [showOtpFieldsverifiedformobile, setShowOtpFieldsverifiedformobile] = useState(false);
  const [verificationStatusformobile, setVerificationStatusformobile] = useState('');
  const [otpformobile, setOtpformobile] = useState("");
  const [messageformobile, setMessageformobile] = useState('');
  const [resendOtpStatusformobile, setResendOtpStatusformobile] = useState('');
  const checkEmailAvailability = async (email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/login/checkavailableemail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.token === null && response.data.message === "email already registered") {
        // Email is not available
        setEmailAvailabilityMessage("Email is already registered. Choose a different email.");
        return false;
      } else {
        // Email is available
        setEmailAvailabilityMessage("");
        return true;
      }
    } catch (error) {
      console.error("Error checking email availability:", error);
      return false;
    }
  };
  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;

    // Check email availability when the user types each character
    const isEmailAvailable = await checkEmailAvailability(newEmail);

    // Update the email state
    setEmail(newEmail);
  };
  const checkMobileAvailability = async (mobile) => {
    if (mobile.length !== 10) {
      setMobileError("Mobile number must be 10 digits.");
      return false;
    } else {
      setMobileError("");
    }
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);

      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/login/checkavailablemobile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.token === null && response.data.message === "mobile already registered") {
        // Mobile is not available
        setMobileAvailabilityMessage("Mobile is already registered. Choose a different mobile number.");
        return false;
      } else {
        // Mobile is available
        setMobileAvailabilityMessage("");
        return true;
      }
    } catch (error) {
      console.error("Error checking mobile availability:", error);
      return false;
    }
  };
  const handleMobileChange = async (e) => {
    const newMobile = e.target.value;

    // Check mobile availability when the user types each character
    const isMobileAvailable = await checkMobileAvailability(newMobile);

    // Update the mobile state
    setMobile(newMobile);
  };  
  const handleVerify = async () => {
    try {
      // Check email availability before proceeding to OTP verification
      const isEmailAvailable = await checkEmailAvailability(email);
  
      if (isEmailAvailable) {
        const response = await axios.put(
          "http://68.178.169.226:12080/api/v1/client/emailotpclient",
          {
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.data.status !== "Error") {
          setShowOtpFields(true);
          setEmailVerified(true);
        } else {
          setRegistrationError(response.data.description);
        }
      } else {
        setRegistrationError("Email is not available. Choose a different email.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleVerifyformobile = async () => {
    try {
      // Check email availability before proceeding to OTP verification
      const isMobileAvailable = await checkMobileAvailability(mobile);
  
      if (isMobileAvailable) {
        const response = await axios.put(
          "http://68.178.169.226:12080/api/v1/client/emailandmobileotp",
          {
            mobile: mobile,
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.data.status !== "Error") {
          setShowOtpFieldsformobile(true);
        } else {
          setRegistrationError(response.data.description);
        }
      } else {
        setRegistrationError("Email is not available. Choose a different email.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
const handleVerifySeller = async () => {
    try {
        const response = await axios.put(
            "http://68.178.169.226:12080/api/v1/seller/verifyseller",
            {
                otp: otp,
                mobile  : email,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        
        console.log("Password reset successful", response);
        if(response.data.status =="Success"){
            setMessage(response.data.description);
            setVerificationStatus('Success');
            setShowOtpFieldsverified(true);
          }else{
            setMessage(response.data.description);
          }
    } catch (error) {
        console.error("Error resetting password:", error);
        // Handle errors (e.g., show error message to the user)
    }
};
const handleVerifySellerformobile = async () => {
  try {
      const response = await axios.put(
          "http://68.178.169.226:12080/api/v1/seller/verifyseller",
          {
              otp: otpformobile,
              mobile  : mobile,
          },
          {
              headers: {
                  "Content-Type": "application/json",
              },
          }
      );
      
      console.log("Password reset successful", response);
      if(response.data.status =="Success"){
        setMessageformobile(response.data.description);
          setVerificationStatusformobile('Success');
          setShowOtpFieldsverifiedformobile(true);
        }else{
          setMessageformobile(response.data.description);
        }
  } catch (error) {
      console.error("Error resetting password:", error);
      // Handle errors (e.g., show error message to the user)
  }
};
const handleResendOtp = async () => {
  try {
    const response = await axios.put(
      "http://68.178.169.226:12080/api/v1/client/emailotpclient",
      {
       email:email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status !== "Error") {
      // Handle success, e.g., show a success message
      setResendOtpStatus('Success');
    } else {
      // Handle error, e.g., show an error message
      setResendOtpStatus(response.data.description);
    }
  } catch (error) {
    console.error("Error resending OTP:", error);
  }
};
const handleResendOtpformobile = async () => {
  try {
    const response = await axios.put(
      "http://68.178.169.226:12080/api/v1/client/emailotpclient",
      {
        mobile: mobile,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status !== "Error") {
      // Handle success, e.g., show a success message
      setResendOtpStatusformobile('Success');
    } else {
      // Handle error, e.g., show an error message
      setResendOtpStatusformobile(response.data.description);
    }
  } catch (error) {
    console.error("Error resending OTP:", error);
  }
};
const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (!firstname || !email || !mobile || !password || !confirmpassword) {
      alert("All fields are mandatory. Please fill in all the required fields.");
      return;
    }
    if (mobile.length !== 10) {
      setMobileError("Mobile number must be 10 digits.");
      return;
    } else {
      setMobileError("");
    }
    try {
      // TODO: Replace with actual API call
      const response = await fetch("http://68.178.169.226:12080/api/v1/login/registeruser", {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        firstname,
          lastname,
          email,
          mobile,
          password,
        }),
      });
  
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }
  if (response.status === 403) {
    alert(response)
  }
      if (response.ok) {
        const data = await response.json();
        console.log("POST request successful");
        if (response.data && response.data.length > 0) {
            setData(response.data);
          } else {
            setData([]); // If no data is received, set an empty array
          }
          if (data.token) {
            navigate('/AfterRegister');
          } else {
            navigate('/AfterRegisterFailed')
          }
        // window.location.reload(); // Refresh the page
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordsMatch(password === confirmpassword);
};

// Function to handle confirm password change and check if they match
const handleConfirmPasswordChange = (e) => {
    const confirmpassword = e.target.value;
    setConfirmPassword(confirmpassword);
    setPasswordsMatch(password === confirmpassword);
};
const handleLogout = () => {
  sessionStorage.clear();
navigate('/AdminLogin');
};
  return (

    

    <div>
      	<LoginHeader/>
        
        <div className="login-outbox d-flex align-items-center  justify-content-center">
        <div className="login-wrappere login">
        <h2>Create Account</h2> 
        <form onSubmit={onFinish}>
              <p>(If register as Buyer,can't register as Seller for email otp please check your spam also)</p>
                        
                      <div className="form-group row">
                          <label className="col-12 col-form-label">Email</label>
                          <div className="col-8">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={email}
                              onChange={handleEmailChange}
                              autoComplete="off"
                              disabled={verificationStatus === 'Success'}
                            />
                            {emailAvailabilityMessage && (
                    <p className="text-danger">{emailAvailabilityMessage}</p>
                  )}
                          </div>
                         { !showOtpFieldsverified &&(
                          <div className="col-4">
                            <button type="button"  className="btn btn-primary" onClick={handleVerify} style={{ width: '100%' }}>
                              Verify
                            </button>
                          </div>)}
                        </div>   
                        
                        {(showOtpFields && !showOtpFieldsverified) &&  (
    <div className="row">
        <div className="col-md-6">
            <div className="form-group">
                <input
                    type="text"
                    name="otp"
                    className="input-text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                />
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group mb-0">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleVerifySeller}
                    style={{ width: "100%" }}
                >
                    Verify OTP
                </button>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleResendOtp}
                style={{ width: "100%" }}
                disabled={resendOtpStatus === 'Success'}
              >
                Resend OTP
              </button>
            </div>
          </div>      
        {message && (
        <div className="row">
          <div className="col-12">
            <p className="text-danger">{message}</p>
          </div>
        </div>
      )}
    </div>
)}
{showOtpFieldsverified && (
       <div className="form-group row">
                          <label className="col-12 col-form-label">Mobile</label>
                          <div className="col-8">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={mobile}
                              onChange={handleMobileChange}
                              autoComplete="off"
                              maxLength={10}
                              disabled={verificationStatusformobile === 'Success'}
                            />
                             {mobileError && (
                    <div className="error-message">{mobileError}</div>
                  )}
                            {mobileAvailabilityMessage && (
                    <p className="text-danger">{mobileAvailabilityMessage}</p>
                  )}
                  </div>
                  { !showOtpFieldsverifiedformobile &&(
                  <div className="col-4">
                            <button type="button"  className="btn btn-primary" onClick={handleVerifyformobile} style={{ width: '100%' }}>
                              Verify
                            </button>
                          </div>)}
                        </div>
)}
                        {(showOtpFieldsformobile && !showOtpFieldsverifiedformobile) &&  (
    <div className="row">
        <div className="col-md-6">
            <div className="form-group">
                <input
                    type="text"
                    name="otp"
                    className="input-text"
                    placeholder="Enter OTP"
                    value={otpformobile}
                    onChange={(e) => setOtpformobile(e.target.value)}
                />
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group mb-0">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleVerifySellerformobile}
                    style={{ width: "100%" }}
                >
                    Verify OTP
                </button>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleResendOtpformobile}
                style={{ width: "100%" }}
                disabled={resendOtpStatusformobile === 'Success'}
              >
                Resend OTP
              </button>
            </div>
          </div>      
        {messageformobile && (
        <div className="row">
          <div className="col-12">
            <p className="text-danger">{messageformobile}</p>
          </div>
        </div>
      )}
    </div>
)}

                        <div className="form-group row">
                          <label htmlFor="text" className="col-12 col-form-label">
                            Name
                          </label>
                          <div className="col-12">
                            <input
                              id="text"
                              name="text"
                              className="form-control here slug-title"
                              type="text"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                 
                        <div className="form-group row">
                          <label className="col-12 col-form-label">Password</label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="password"
                              value={password}
                              onChange={handleNewPasswordChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-12 col-form-label">Confirm Password</label>
                          <div className="col-12">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="password"
                              value={confirmpassword}
                              onChange={handleConfirmPasswordChange}
                              autoComplete="off"
                            />
                            {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button name="submit" type="submit" className="btn btn-primary">
                              Register
                            </button>
                          </div>
                        </div>
                        {!emailVerified && registrationError && (
  <div className="row">
    <div className="col-12">
      <p className="text-danger">{registrationError}</p>
    </div>
  </div>
)}
                      </form>
        </div>
        </div>
     
    </div>
  );
};

export default Seller;
