// EditSubCategory.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import ConfirmationModal from "../src/editCnfirmation/ConfirmationModal";

const EditSubSubCategoryModal = ({
  show,
  onHide,
  onSave,
  categorydata,
  category,
  subcategoryList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);
    }
  }, [show, category, form]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      onSave(formData);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    // Proceed with saving changes
    handleSave();
  }; 
  return (
    <Modal
      title="Edit Sub Category"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={handleShowConfirmationModal}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="subsubcategoryname" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="sortdescription" label="Title (META TAG)">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="categoryid" label="Parent Category">
  <Select>
    {categorydata.map((category) => (
      <Select.Option key={category.categoryid} value={category.categoryid}>
        {category.categoryname}
      </Select.Option>
    ))}
  </Select>
</Form.Item>
<Form.Item name="subcategoryname" label="Sub Category">
<Select>
    {subcategoryList.map((subcategory) => (
      <Select.Option key={subcategory.subcategoryid} value={subcategory.subcategoryid}>
        {subcategory.subcategoryname}
      </Select.Option>
    ))}
  </Select>
        </Form.Item>
        <Form.Item name="fulldescription" label="Description (META TAG)">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="producttag" label="Product Tags">
          <Input />
        </Form.Item>
      </Form>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Modal>
  );
};

export default EditSubSubCategoryModal;
