import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker, Row, Col } from "antd";
import "../style.scss";
import "../riot_admin.scss";
import SideBar from "../sidebar";
import Header from "../Header";
import axios from "axios";
import moment from "moment";
import { Modal, Form, Input, Select } from "antd";
const { Option } = Select;
const OrderDetail = (orderid) => {
  const { orderId } = useParams();
  const token = localStorage.getItem("token");
  const [orderData, setOrderData] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const currentDate = moment();
  const [cancelReasonModalVisible, setCancelReasonModalVisible] =
    useState(false);
  const mapOrderStatusToStep = (orderStatus) => {
    switch (orderStatus) {
      case "Placed":
        return "Placed";
      case "OrderConfirmed":
        return "Confirmed Order";
      case "processingorder":
        return "Processing Order";
      case "productdispatched":
        return "Product Dispatched";
      case "OutForDelivery":
        return "On Delivery";
      case "Delivered":
        return "Product Delivered";
      default:
        return "";
    }
  };
  const [trackingSteps, setTrackingSteps] = useState([]);
  const updateTrackingSteps = (orderStatus) => {
    const steps = [
      { title: "Confirmed Order", icon: "cart", completed: false },
      { title: "Processing Order", icon: "tumblr-reblog", completed: false },
      { title: "Product Dispatched", icon: "gift", completed: false },
      { title: "On Delivery", icon: "truck-delivery", completed: false },
      { title: "Product Delivered", icon: "hail", completed: false },
    ];
    const mappedSteps = steps.map((step) => {
      if (step.title === mapOrderStatusToStep(orderStatus)) {
        return { ...step, completed: true };
      } else {
        return { ...step, completed: false };
      }
    });

    setTrackingSteps(mappedSteps);
  };

  useEffect(() => {
    updateTrackingSteps(orderData?.orderStatus || "");
  }, [orderData?.orderStatus]);

  const getOrderDetails = async () => {
    const selectedID = localStorage.getItem("selectedID");
    try {
      console.log("orderid", orderId);
      const payload = {
        orderid: orderId ? orderId : selectedID,
      };
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/order/getbyid",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const dataOrder = response.data;
        setOrderData(dataOrder);
      } else {
        console.error("Failed to fetch order details");
      }
    } catch (error) {
      console.error("Error fetching Order details:", error);
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  const handleStatusChange = async (newStatus, reason = null) => {
    // Show a confirmation popup using window.alert
    const confirmed = window.confirm(
      `Are you sure you want to change the order status to ${newStatus}?`
    );
    try {
      const payload = {
        orderid: orderData.orderid,
        orderStatus: newStatus,
        admincancellreason: reason,
      };

      if (confirmed) {
        const response = await axios.post(
          "http://68.178.169.226:12080/api/v1/order/editstatus",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("Order status updated successfully");
          // You might want to update the orderData state with the new data received from the server
          // For example, call getOrderDetails again after updating the status
          getOrderDetails();
        } else {
          console.error("Failed to update order status");
        }
      } else {
        // If the user cancels the confirmation, you can handle it accordingly
        console.log("Order status change cancelled");
      }
    } catch (error) {
      console.error("Error updating Order status:", error);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);
  const handleCancelReasonSubmit = (reason) => {
    handleStatusChange("Cancelled", reason);
    setCancelReasonModalVisible(false);
  };
  return (
    <div class="wrapper">
      <SideBar />
      <div class="ec-page-wrapper">
        <Header />
        <div class="ec-content-wrapper">
          <div class="content">
            <div class="breadcrumb-wrapper breadcrumb-wrapper-2">
              <h1>Order Detail</h1>
              <p class="breadcrumbs">
                <span>
                  <a href="index.html">Home</a>
                </span>
                <span>
                  <i class="mdi mdi-chevron-right"></i>
                </span>
                Orders
              </p>
            </div>
            {orderData ? (
              <div class="row">
                <div class="card mt-4 trk-order">
                  <div class="p-4 text-center text-white text-lg bg-dark rounded-top">
                    <span class="text-uppercase">Tracking Order No - </span>
                    <span class="text-medium">{orderData.ordernumber}</span>
                  </div>
                  <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
                    <div class="w-100 text-center py-1 px-2">
                      <span class="text-medium">Shipped Via:</span> UPS Ground
                    </div>
                    <div class="w-100 text-center py-1 px-2">
                      <span class="text-medium">Status:</span>
                      {orderData.orderStatus}
                    </div>
                    <div class="w-100 text-center py-1 px-2">
                      <span class="text-medium">Expected Date:</span>{" "}
                      <DatePicker defaultValue={currentDate} />
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                      {trackingSteps.map((step) => (
                        <div
                          key={step.title}
                          className={`step ${
                            step.completed ? "completed" : ""
                          }`}
                        >
                          <div className="step-icon-wrap">
                            <div className="step-icon">
                              <i className={`mdi mdi-${step.icon}`}></i>
                            </div>
                          </div>
                          <h4 className="step-title">{step.title}</h4>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="ec-odr-dtl card card-default">
                    <div class="card-header card-header-border-bottom d-flex ">
                      <h2 class="ec-odr">
                        Order Detail
                        <br />
                        <span class="small justify-content-right col-7">
                          Order ID: #{orderData.orderid}
                        </span>
						<Col span={8} className="justify-content-end d-flex">
                        <Select
                          id="statusDropdown"
                          value={orderData.orderStatus}
						  className="w-50"
                          onChange={(value) => {
                            if (value === "Cancelled") {
                              setCancelReasonModalVisible(true);
                            } else {
                              handleStatusChange(value);
                            }
                          }}
                        >
                          {orderData.orderStatus !== "Cancelled" &&
                            orderData.orderStatus !== "Delivered" &&
                            orderData.orderStatus !== "Returned" && (
                              <>
                                {orderData.orderStatus === "Placed" && (
                                  <>
                                    <Option value="Placed">Placed</Option>
                                    <Option value="OrderConfirmed">
                                      Order Confirmed
                                    </Option>
                                    <Option value="processingorder">
                                      Processing Order
                                    </Option>
                                    <Option value="productdispatched">
                                      Product Dispatched
                                    </Option>
                                    <Option value="OutForDelivery">
                                      Out For Delivery
                                    </Option>
                                    <Option value="Delivered">Delivered</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                    <Option value="Returned">Returned</Option>
                                  </>
                                )}
                                {orderData.orderStatus === "OrderConfirmed" && (
                                  <>
                                    <Option value="OrderConfirmed">
                                      Order Confirmed
                                    </Option>
                                    <Option value="processingorder">
                                      Processing Order
                                    </Option>
                                    <Option value="productdispatched">
                                      Product Dispatched
                                    </Option>
                                    <Option value="OutForDelivery">
                                      Out For Delivery
                                    </Option>
                                    <Option value="Delivered">Delivered</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                    <Option value="Returned">Returned</Option>
                                  </>
                                )}
                                {orderData.orderStatus ===
                                  "processingorder" && (
                                  <>
                                    <Option value="processingorder">
                                      Processing Order
                                    </Option>
                                    <Option value="productdispatched">
                                      Product Dispatched
                                    </Option>
                                    <Option value="OutForDelivery">
                                      Out For Delivery
                                    </Option>
                                    <Option value="Delivered">Delivered</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                    <Option value="Returned">Returned</Option>
                                  </>
                                )}
                                {orderData.orderStatus ===
                                  "productdispatched" && (
                                  <>
                                    <Option value="productdispatched">
                                      Product Dispatched
                                    </Option>
                                    <Option value="OutForDelivery">
                                      Out For Delivery
                                    </Option>
                                    <Option value="Delivered">Delivered</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                    <Option value="Returned">Returned</Option>
                                  </>
                                )}
                                {orderData.orderStatus === "OutForDelivery" && (
                                  <>
                                    <Option value="OutForDelivery">
                                      Out For Delivery
                                    </Option>
                                    <Option value="Delivered">Delivered</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                    <Option value="Returned">Returned</Option>
                                  </>
                                )}
                              </>
                            )}
                          {orderData.orderStatus === "Delivered" && (
                            <Option value="Delivered">Delivered</Option>
                          )}
                          {orderData.orderStatus === "Cancelled" && (
                            <Option value="Cancelled">Cancelled</Option>
                          )}
                          {orderData.orderStatus === "Returned" && (
                            <Option value="Returned">Returned</Option>
                          )}
                        </Select>
						</Col>
                        <Modal
                          visible={cancelReasonModalVisible}
                          title="Enter Cancellation Reason"
                          onCancel={() => setCancelReasonModalVisible(false)}
                          footer={null}
                        >
                          <Form
                            onFinish={(values) =>
                              handleCancelReasonSubmit(values.reason)
                            }
                          >
                            <Form.Item name="reason" label="Reason">
                              <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item>
                              <button class="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </Form.Item>
                          </Form>
                        </Modal>
                      </h2>
                    </div>

                    <div class="card-body">
                      <div class="row">
                        <div class="col-xl-3 col-lg-6">
                          <address class="info-grid">
                            <div class="info-title">
                              <strong>Billing Address:</strong>
                            </div>
                            <br />
                            <div class="info-content">
                              {orderData.billingaddress}
                            </div>
                          </address>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                          <address class="info-grid">
                            <div class="info-title">
                              <strong>Shipped Address:</strong>
                            </div>
                            <br />
                            <div class="info-content">
                              {orderData.shippingaddress.name}
                              <br />
                              {orderData.shippingaddress.address1},
                              {orderData.shippingaddress.address2}
                              <br />
                              {orderData.shippingaddress.city},
                              {orderData.shippingaddress.state},
                              {orderData.shippingaddress.country}
                              <br />
                              {orderData.shippingaddress.pincode}
                              <br />
                              {orderData.shippingaddress.phonenumber}
                            </div>
                          </address>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                          <address class="info-grid">
                            <div class="info-title">
                              <strong>Payment Method:</strong>
                            </div>
                            <br />
                            <div class="info-content">
                              {orderData.paymentmethod === "cod"
                                ? "Cash On Delivery"
                                : orderData.paymentmethod}
                            </div>
                          </address>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                          <address className="info-grid">
                            <div className="info-title">
                              <strong>Order Date:</strong>
                            </div>
                            <br />
                            <div className="info-content">
                              {orderData.createDate && (
                                <div>
                                  <p>
                                    {new Date(
                                      orderData.createDate
                                    ).toLocaleDateString()}
                                  </p>
                                  <p>
                                    {new Date(
                                      orderData.createDate
                                    ).toLocaleTimeString()}
                                  </p>
                                </div>
                              )}
                            </div>
                          </address>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <h3 class="tbl-title">PRODUCT SUMMARY</h3>
                          <div class="table-responsive">
                            <table class="table table-striped o-tbl">
                              <thead>
                                <tr class="line">
                                  <td>
                                    <strong>#</strong>
                                  </td>
                                  <td class="text-center">
                                    <strong>IMAGE</strong>
                                  </td>
                                  <td class="text-center">
                                    <strong>PRODUCT</strong>
                                  </td>
                                  <td class="text-center">
                                    <strong>PRICE/UNIT</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>QUANTITY</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>SUBTOTAL</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {orderData.products.map((product, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <img
                                        class="product-img"
                                        src={product.productimage}
                                        alt=""
                                      />
                                    </td>
                                    <td>
                                      <strong>{product.productname}</strong>
                                      <br />
                                      <td
                                        dangerouslySetInnerHTML={{
                                          __html: product.sortdescription,
                                        }}
                                      ></td>
                                    </td>
                                    <td class="text-center">
                                      {product.sellingprice}
                                    </td>
                                    <td class="text-center">
                                      {product.productcount}
                                    </td>
                                    <td class="text-right">
                                      {orderData.subtotal}
                                    </td>
                                  </tr>
                                ))}
                                {/* <tr>
																<td colspan="4"></td>
																<td class="text-right"><strong>Taxes</strong></td>
																<td class="text-right"><strong>N/A</strong></td>
															</tr> */}
                                <tr>
                                  <td colspan="4"></td>
                                  <td class="text-right">
                                    <strong>Total</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>{orderData.total}</strong>
                                  </td>
                                </tr>

                                <tr>
                                  <td colspan="4"></td>
                                  <td class="text-right">
                                    <strong>Payment Status</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>{orderData.paymentstatus}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Render a loading state or handle the absence of data
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetail;
