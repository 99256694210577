import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; 
import './style.scss';
import './riot_admin.scss';
import './materialdesignicons.scss';
import Logo  from "./images/logo-web.jpg";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const menuItems = [
	// { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed  
  ];
const SideBar = () => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token has expired
        setIsTokenExpired(true);
        localStorage.removeItem('token');
        navigate("/AdminLogin");
      } else {
        // Token is still valid
        setIsTokenExpired(false);
      }
    }
  }, []);

      const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
		banner:false,
		offer:false,
		cinfo:false,
		cdetails:false,
		cminfo:false,
		coupon:false,
		pincode:false,
		deliverycharges:false,
		showpincode:false,
		vendor:false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  function handleLogout() {
		// Navigate to the login page
		window.location.href = '/AdminLogin'; 
	  }
	  
    return(
        <div class="ec-left-sidebar ec-bg-sidebar">
			<div id="sidebar" class="sidebar ec-sidebar-footer">

				<div class="ec-brand">
					<a href="index" title="Ekka">
          <div className='navbar-logo '>
					<img
              id="sidebar-toggler"
              className="sidebar-toggle" src={Logo}
			  style={{width:"170px"}}
            ></img></div>
						 
						{/* <span class="ec-brand-name text-truncate">Shopeasey</span> */}
					</a>
				</div>

				<div class="ec-navigation" data-simplebar>
					<ul class="nav sidebar-inner" id="sidebar-menu">
						{menuItems.map((item, index) => (
                  <li key={index}>
                    <a className="sidenav-item-link" href={item.link}>
                      <i className={item.icon}></i>
                      <span className="nav-text">{item.text}</span>
                    </a>
                  </li>
                ))}
						{/* <!-- Vendors --> */}
						{/* <li className={`has-sub ${isSubMenuOpen.vendors ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('vendors')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Vendors</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.vendors ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
            <li className="">
              <a className="sidenav-item-link" href="vendor-card">
                <span className="nav-text">Vendor Grid</span>
              </a>
            </li>

									<li class="">
										<a class="sidenav-item-link" href="vendor-list">
											<span class="nav-text">Vendor List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="vendor-profile">
											<span class="nav-text">Vendors Profile</span>
										</a>
									</li>
								</ul>
							</div>
						</li> */}

						{/* <!-- Users --> */}
                        {/* <li className={`has-sub ${isSubMenuOpen.user ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('user')}>
          <i className="mdi mdi-account-group"></i>
          <span className="nav-text">Users</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.user ? 'show' : ''}`}>
          <ul className="sub-menu" id="users" data-parent="#sidebar-menu">
                      <li>
                        <a className="sidenav-item-link" href="user-card">
                          <span className="nav-text">User Grid</span>
                        </a>
                      </li>

									<li class="">
										<a class="sidenav-item-link" href="user-list">
											<span class="nav-text">User List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="user-profile">
											<span class="nav-text">Users Profile</span>
										</a>
									</li>
								</ul>
							</div>
							 <hr/>
						</li> */}

						{/* <!-- Category --> */}
                        <li className={`has-sub ${isSubMenuOpen.categories ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('categories')}>
    <i className="mdi mdi-dns-outline"></i>
    <span className="nav-text">Categories</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.categories ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="main-category">
          <span className="nav-text">Main Category</span>
        </a>
      </li>
      <li className="">
        <a className="sidenav-item-link" href="sub-category">
          <span className="nav-text">Sub Category</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="subsub-category">
          <span className="nav-text">SubSub Category</span>
        </a>
      </li>
    </ul>
  </div>
</li>


						{/* <!-- Products --> */}
						<li className={`has-sub ${isSubMenuOpen.products ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('products')}>
          <i className="mdi mdi-palette-advanced"></i>
          <span className="nav-text">Products</span> 
		  <b className="caret"> </b>
        </a>
        <div className={`collapse ${isSubMenuOpen.products ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="product-add">
                          <span className="nav-text">Add Product</span>
                        </a>
                      </li>
					  {/* <li className="">
                        <a className="sidenav-item-link" href="product-edit">
                          <span className="nav-text">Edit Product</span>
                        </a>
                      </li> */}
									<li class="">
										<a class="sidenav-item-link" href="product-list">
											<span class="nav-text">List Product</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="quantity_update">
											<span class="nav-text">Quantity Update</span>
										</a>
									</li>
									{/* <li class="">
										<a class="sidenav-item-link" href="product-grid">
											<span class="nav-text">Grid Product</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="product-detail">
											<span class="nav-text">Product Detail</span>
										</a>
									</li> */}
								</ul>
							</div>
						</li>
						<li className={`has-sub ${isSubMenuOpen.banner ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('banner')}>
    <i className="mdi mdi-camera-image"></i>
    <span className="nav-text">Banner</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.banner ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="main-banner">
          <span className="nav-text">Add Banner</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="main-offer">
          <span className="nav-text">Add Offer</span>
        </a>
      </li>
    </ul>
  </div>
</li>
<li className={`has-sub ${isSubMenuOpen.vendor ? 'expand' : ''}`}>
  <a href="main-Vendors" className="sidenav-item-link">
    <i className="mdi mdi-account-tie"></i>
    <span className="nav-text">Sellers</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.vendor ? 'expand' : ''}`}>
  <a href="main-sellerPlan" className="sidenav-item-link">
    <i className="mdi mdi-account-tie"></i>
    <span className="nav-text">Seller Plan</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
  <a href="main-common" className="sidenav-item-link">
    <i className="mdi mdi-creative-commons"></i>
    <span className="nav-text">Common Info</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cinfo ? 'expand' : ''}`}>
  <a href="main-Icons" className="sidenav-item-link">
    <i className="mdi mdi-creative-commons"></i>
    <span className="nav-text">Social Media</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.cdetails ? 'expand' : ''}`}>
  <a href="clientDetails" className="sidenav-item-link">
    <i className="mdi mdi-account-group"></i>
    <span className="nav-text">User</span>
  </a>
</li>
	{/* <!-- Orders --> */}
	<li className={`has-sub ${isSubMenuOpen.orders ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('orders')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Orders</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.orders ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="new-order">
                          <span className="nav-text">New Order</span>
                        </a>
                      </li>
									{/* <li class="">
										<a class="sidenav-item-link" href="order-history">
											<span class="nav-text">Order History</span>
										</a>
									</li> */}
									{/* <li class="">
										<a class="sidenav-item-link" href="order-detail">
											<span class="nav-text">Order Detail</span>
										</a>
									</li> */}
									{/* <li class="">
										<a class="sidenav-item-link" href="invoice">
											<span class="nav-text">Invoice</span>
										</a>
									</li> */}
								</ul>
							</div>
						</li>
						{/* <li className={`has-sub ${isSubMenuOpen.showpincode ? 'expand' : ''}`}>
  <a href="main-pincode" className="sidenav-item-link">
    <i className="mdi mdi-map-marker"></i>
    <span className="nav-text">Show Pincodes</span>
  </a>
</li> */}
						<li className={`has-sub ${isSubMenuOpen.pincode ? 'expand' : ''}`}>
  <a href="main-pincodes" className="sidenav-item-link">
    <i className="mdi mdi-map-marker"></i>
    <span className="nav-text">Available Pincodes</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.deliverycharges ? 'expand' : ''}`}>
  <a href="delivery-charges" className="sidenav-item-link">
    <i className="mdi mdi-currency-inr"></i>
    <span className="nav-text">Delivery Charges</span>
  </a>
</li>
<li className={`has-sub ${isSubMenuOpen.products ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('coupon')}>
          <i className="mdi mdi-ticket-percent"></i>
          <span className="nav-text">Coupon</span> 
		  <b className="caret"> </b>
        </a>
        <div className={`collapse ${isSubMenuOpen.coupon ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="coupon">
                          <span className="nav-text">Add Coupon</span>
                        </a>
                      </li>
									<li class="">
										<a class="sidenav-item-link" href="ListCoupons">
											<span class="nav-text">List Coupon</span>
										</a>
									</li>
								</ul>
							</div>
						</li>
<li className={`has-sub ${isSubMenuOpen.tcondition ? 'expand' : ''}`}>
  <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('tcondition')}>
    <i className="mdi mdi-image-filter-none"></i>
    <span className="nav-text">Other Pages</span> 
	<b className="caret"></b>
  </a>
  <div className={`collapse ${isSubMenuOpen.tcondition ? 'show' : ''}`}>
    <ul className="sub-menu" id="categorys" data-parent="#sidebar-menu">
      <li className="">
        <a className="sidenav-item-link" href="about-us">
          <span className="nav-text">About Us</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="privacy-policy">
          <span className="nav-text">Privacy Policy</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="customer-service">
          <span className="nav-text">Customer Service</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="terms-conditions">
          <span className="nav-text">Terms & Conditions</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="terms-conditions-seller">
          <span className="nav-text">Terms & Conditions for Seller</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="agreed-process">
          <span className="nav-text">Agreed Process</span>
        </a>
      </li>
	  <li className="">
        <a className="sidenav-item-link" href="contact-us">
          <span className="nav-text">Contact Us</span>
        </a>
      </li>
    </ul>
  </div>
</li>

<li className={`has-sub ${isSubMenuOpen.cdetails ? 'expand' : ''}`}>
  <a href="CurrencyPage" className="sidenav-item-link">
    <i className="mdi mdi-cash"></i>
    <span className="nav-text">Currency</span>
  </a>
</li>
					

						{/* <!-- Reviews --> */}
						{/* <li>
							<a class="sidenav-item-link" href="review-list">
								<i class="mdi mdi-star-half"></i>
								<span class="nav-text">Reviews</span>
							</a>
						</li> */}

						{/* <!-- Brands --> */}
						{/* <li>
							<a class="sidenav-item-link" href="brand-list">
								<i class="mdi mdi-tag-faces"></i>
								<span class="nav-text">Brands</span>
							</a>
							<hr/> 
						</li> */}

						{/* <!-- Authentication --> */}
						{/* <li className={`has-sub ${isSubMenuOpen.authentication ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('authentication')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Authentication</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.authentication ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a href="sign-in">
                          <span className="nav-text">Sign In</span>
                        </a>
                      </li>
									<li class="">
										<a href="sign-up">
											<span class="nav-text">Sign Up</span>
										</a>
									</li>
								</ul>
							</div>
						</li> */}

						{/* <!-- Icons --> */}
						{/* <li className={`has-sub ${isSubMenuOpen.icons ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('icons')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Icons</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.icons ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
                      <li className="">
                        <a className="sidenav-item-link" href="material-icon">
                          <span className="nav-text">Material Icon</span>
                        </a>
                      </li>
									<li class="">
										<a class="sidenav-item-link" href="font-awsome-icons">
											<span class="nav-text">Font Awsome Icon</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="flag-icon">
											<span class="nav-text">Flag Icon</span>
										</a>
									</li>
								</ul>
							</div>
						</li> */}

						{/* <!-- Other Pages --> */}
                        {/* <li className={`has-sub ${isSubMenuOpen.otherpages ? 'expand' : ''}`}>
							<a class="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('otherpages')}>
								<i class="mdi mdi-image-filter-none"></i>
								<span class="nav-text">Other Pages</span> <b class="caret"></b>
							</a>
							<div className={`collapse ${isSubMenuOpen.otherpages ? 'show' : ''}`}>
								<ul class="sub-menu" id="otherpages" data-parent="#sidebar-menu">
									<li class="has-sub">
										<a href="404">404 Page</a>
									</li>
								</ul>
							</div>
						</li> */}
		 {/* <li>
		 <Link to="/AdminLogin" className="sidenav-item-link">
                <i className="mdi mdi-star-half"></i>
                <span className="nav-text">Logout</span>
              </Link>
						</li>  */}
					</ul>
				</div>
			</div>
		</div>
	
    );
}
export default SideBar;