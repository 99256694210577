import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import Header from '../Header';
import ReactQuill from 'react-quill';
import axios from "axios";

const EditAgreeProcess = () =>{
	const navigate = useNavigate();
	const [termsConditionsforseller, setTermsconditions] = useState('');
	const [commonDetailsId, setCommonDetailsId] = useState(''); // Assuming you have a way to obtain this ID
	const [commonDetailsField, setCommonDetailsField] = useState('agreedProcessPay'); 
	const fetchCommonDetails = async () => {
		try {
			const payload={
				commondetailsfield: 'agreedProcessPay',
			}
		  const response = await axios.put("http://68.178.169.226:12080/api/v1/commondetails/getcommondetails",payload,{
			
		  });
		  if (response.status === 200) {
			// Successfully fetched common details
			const commonDetails = response.data;
			setTermsconditions(commonDetails.commondetails);
			// Do something with commonDetails, such as updating your component's state
			console.log("Common Details:", commonDetails);
			
		  } else {
			console.error("Failed to fetch common details");
		  }
		} catch (error) {
		  console.error("Error fetching common details:", error);
		}
	  };
	
	  // useEffect to fetch common details when the component mounts
	  useEffect(() => {
		fetchCommonDetails();
	  }, []);
	const handleSubmit = (e) => {
		e.preventDefault();
		editCommonDetails();
	  };
	const editCommonDetails = async () => {
		try {
		  const payload = {
			commondetailsid: commonDetailsId, // Pass the common details ID you want to edit
			commondetails: termsConditionsforseller,
			commondetailsfield: commonDetailsField,
		  };
	
		  const response = await axios.post("http://68.178.169.226:12080/api/v1/commondetails/editcommondetails", payload, {
			headers: {
			  Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		  });
	
		  if (response.status === 200) {
			// Successfully edited common details
			const editedCommonDetails = response.data;
			// Do something with the editedCommonDetails, if needed
			console.log("Edited Common Details:", editedCommonDetails);
			navigate(-1);
		  } else {
			console.error("Failed to edit common details");
		  }
		} catch (error) {
		  console.error("Error editing common details:", error);
		}
	  };
    return(
        <div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>


		<div class="ec-page-wrapper">
			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Agreed Process To Payment</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Agreed Process To Payment</p>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
									<div className="row">
                       
                      </div> 
										<form onSubmit={handleSubmit}
										>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">Description</label> 
												<div className="col-12">
                                                <ReactQuill
													value={termsConditionsforseller}
													onChange={(value) => setTermsconditions(value)}
													/>

												</div>
											</div> 
											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div> 
</div>
	</div>
    );
}
export default EditAgreeProcess;