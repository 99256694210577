import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import "../../../style.scss";
import "../../../riot_admin.scss";
import SideBar from "../../../vendor/sidebar/index";
import VendorHeader from "../../../../src/vendor/header";
import moment from 'moment';

const AllPayments = () => {
  const token = sessionStorage.getItem('tokenforseller');
  const navigate = useNavigate();
  const [clientId, setClientId] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch("http://68.178.169.226:12080/api/v1/login/getuserdetails", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          setClientId(responseData.id);

          const secondApiResponse = await fetch("http://68.178.169.226:12080/api/v1/sellerpayment/get", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              sellerid: responseData.id,
            }),
          });

          if (secondApiResponse.ok) {
            const secondApiData = await secondApiResponse.json();
            console.log('Second API request successful', secondApiData);
            setPaymentDetails(secondApiData); // Set the payment details in state
          } else {
            console.error('Second API request failed');
          }
        } else {
          console.error('Error fetching client details');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    fetchClientDetails();
  }, [token]);

  const columns = [
    { name: "planname", selector: row => row.planname, sortable: true },
    { name: "amount", selector: row => row.amount, sortable: true },
    { name: "noofcategories", selector: row => row.noofcategories, sortable: true },
    { name: "noofproducts", selector: row => row.noofproducts, sortable: true },
    {
      name: "Date",
      selector: row => row.createDate,
      sortable: true,
      cell: (row) => moment(row.createDate).format("YYYY-MM-DD HH:mm"),
    },
    { name: "Payment Info", selector: row => row.paymentinfo.redirectStatus, sortable: true },
    {
        name: "View Receipt",
        cell: (row) => (
          <button className="nav-link"onClick={() => handleViewReceipt(row)}>View</button>
        ),
      },
  ];
  const handleViewReceipt = (row) => {
    // Redirect to Payment Receipt page with selected row details
    navigate(0, { state: { paymentDetails: row } });
     const receiptURL = `/PaymentReceipt`; // Adjust this according to your URL structure
  
  // Open the link in a new tab
  window.open(receiptURL, '_blank');
    
  };
  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <VendorHeader />
        <div className="ec-content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Payment Details</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ec-cat-list card card-default mb-24px">
                  <DataTable
                    title=""
                    columns={columns}
                    data={paymentDetails}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPayments;
