// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {DatePicker, Switch,notification  } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../../src/editCnfirmation/ConfirmationModal";
import moment from 'moment';
import DateTimePicker from '../constant';
const EditCoupon = ({
  category,
  show,
  onHide,
  onSave,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [couponname, setcouponname] = useState(category.couponname);
  const [couponcode, setcouponcode] = useState(category.couponcode);
  const [coupondescription, setcoupondescription] = useState(category.coupondescription);
  const [couponamount, setcouponamount] = useState(category.couponamount);
  const [minimumordervalueamount, setminimumordervalueamount] = useState(category.minimumordervalueamount);
  const [couponusage, setcouponusage] = useState(category.couponusage);
  const [totalusage, settotalusage] = useState(category.totalusage);
  const [firsttime, setfirsttime] = useState(category.firsttime);
  const [unlimited, setUnlimited] = useState(category.unlimited);
  const [validstart, setvalidstart] = useState(category.validstart);
  const [validend, setvalidend] = useState(category.validend);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleSaveChanges = async () => {
    const updatedCategory = {
        couponid: category.couponid, 
      couponname,
      couponcode,
      coupondescription,
      couponamount,
      minimumordervalueamount,
      couponusage,
      firsttime,
      unlimited,
      validstart,
      validend,
      totalusage,
    };
  
    try {
      setLoading(true);
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/coupon/addedit",
        updatedCategory,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      notification.success({
        message: response.data.description,
        duration: 3, // Duration in seconds
      });
      fetchData();
      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(updatedCategory); // Notify the parent component about the update
        // onHide(); // Close the modal
      } else {
        console.error("Failed to update category");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };
  
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    handleSaveChanges();
  };  
  
  return (
    <Modal show={show} onHide={onHide}>
      {/* Modal content */}
      <Modal.Header closeButton>
        <Modal.Title>Edit Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="couponname">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="couponname"
              value={couponname}
              onChange={(e) => setcouponname(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="slug">
            <Form.Label>Code</Form.Label>
            <Form.Control
              type="text"
              name="couponcode"
              value={couponcode}
              onChange={(e) => setcouponcode(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="coupondescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="coupondescription"
              value={coupondescription}
              onChange={(e) => setcoupondescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="couponamount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="couponamount"
              value={couponamount}
              onChange={(e) => setcouponamount(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="minimumordervalueamount">
            <Form.Label>Minimum Order Value</Form.Label>
            <Form.Control
              type="number"
              name="minimumordervalueamount"
              value={minimumordervalueamount}
              onChange={(e) => setminimumordervalueamount(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="categorylocation">
            <Form.Label>User Limit</Form.Label>
            <Form.Control
              type="number"
              name="couponusage"
              value={couponusage}
              onChange={(e) => setcouponusage(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="categorylocation">
            <Form.Label>Total Usage</Form.Label>
            <Form.Control
              type="number"
              name="totalusage"
              value={totalusage}
              onChange={(e) => settotalusage(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="firsttime">
            <Form.Label>First Time</Form.Label>
            <Switch
    id="firsttime"
    name="firsttime"
    checked={firsttime}
    onChange={(checked) => setfirsttime(checked)}
    label={firsttime ? 'Yes' : 'No'}
  />
          </Form.Group>
          <Form.Group controlId="unlimited">
            <Form.Label>Unlimited Times</Form.Label>
            <Switch
    id="unlimited"
    name="unlimited"
    checked={unlimited}
    onChange={(checked) => setUnlimited(checked)}
    label={unlimited ? 'Yes' : 'No'}
  />
          </Form.Group>
          <Form.Group controlId="validstart">
  <Form.Label>Start Date</Form.Label>
  <DatePicker
    value={validstart ? moment(validstart) : null} // Convert validstart to moment object
    showTime 
    format="YYYY-MM-DD hh:mm A" 
    onChange={(date) => setvalidstart(date && date.toDate())}
  />
            {/* <DateTimePicker
              label="Valid Start" 
              value={moment(validstart).format('YYYY-MM-DDTHH:mm')} 
              onChange={(newValue) => setvalidstart(newValue)} 
            /> */}
          </Form.Group>
          <Form.Group controlId="validend">
            <Form.Label>End Date</Form.Label>
            <DatePicker
    value={validend ? moment(validend) : null} // Convert validstart to moment object
    showTime 
    format="YYYY-MM-DD hh:mm A" 
    onChange={(date) => setvalidend(date && date.toDate())}
  />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleShowConfirmationModal} disabled={loading}>
          Save Changes
        </Button>
        
      </Modal.Footer>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Modal>
  );
};

export default EditCoupon;
