import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => { 
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("email");
    const [email, setEmail] = useState(""); // Add state for email input
    const [mobile, setMobile] = useState(""); // Add state for email input
    const [password, setPasswrd] = useState(""); // Add state for email input
    const [confirmpasssword, setConfirmpasssword] = useState(""); // Add state for email input
    const [otp, setOtp] = useState("");
  const [otpResponseMessage, setOtpResponseMessage] = useState("");
  const [showOtpFieldforlogin, setShowOtpFieldforlogin] = useState(false);
  const [otpResponseMessageformobile, setOtpResponseMessageformobile] = useState("");
  const [showOtpFieldforloginformobile, setShowOtpFieldforloginformobile] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handleOptionChange = (option) => {
      setSelectedOption(option);
      setOtp("");
      setOtpResponseMessage("");
      setShowOtpFieldforlogin(false);
      setOtpResponseMessageformobile("");
      setShowOtpFieldforloginformobile(false);
    };
    const sendOtp = async () => {
        try {
          // Create a request payload with the email address
          const requestData = {
            email: email,
          };
    
          // Make an HTTP PUT request to the emailotpclient API
          const response = await axios.put(
            'http://68.178.169.226:12080/api/v1/client/emailotpclient',
            requestData
          );
    
          if (response.status === 200) {
            // Successfully sent OTP
            setOtpResponseMessage('OTP sent successfully. Check your email for the OTP.');
            setShowOtpFieldforlogin(true);
          } else {
            // Handle other response statuses if needed
            setOtpResponseMessage('Failed to send OTP. Please try again.');
          }
        } catch (error) {
          // Handle errors, e.g., network issues
          console.error('Error sending OTP:', error);
          setOtpResponseMessage('An error occurred while sending OTP. Please try again later.');
        }
      };
      const sendOtpformobile = async () => {
        try {
          // Create a request payload with the email address
          const requestData = {
            mobile: mobile,
          };
    
          // Make an HTTP PUT request to the emailotpclient API
          const response = await axios.put(
            'http://68.178.169.226:12080/api/v1/client/mobileotpclient',
            requestData
          );
    
          if (response.status === 200) {
            // Successfully sent OTP
            setOtpResponseMessageformobile('OTP sent successfully. Check your email for the OTP.');
            setShowOtpFieldforloginformobile(true);
          } else {
            // Handle other response statuses if needed
            setOtpResponseMessageformobile('Failed to send OTP. Please try again.');
          }
        } catch (error) {
          // Handle errors, e.g., network issues
          console.error('Error sending OTP:', error);
          setOtpResponseMessageformobile('An error occurred while sending OTP. Please try again later.');
        }
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        // Add any additional form submission logic here
        // For example, you can trigger OTP sending logic for email here
        if (selectedOption === "email") {
          sendOtp();
        }
        else{
            sendOtpformobile();
        }
      };
    const handleVerifyOtp = async () => {
        try {
          const response = await axios.put("http://68.178.169.226:12080/api/v1/seller/forgotpassword", {
            otp,
            email:email ,
            newpassword:password,
          });
      
          // Assuming your API returns a token on success
          if (response.data.status === "Success") {
            console.log("Navigating to AdminLogin");
            // Navigate to AdminLoginPage
            navigate("/AdminLogin");
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
        }
      };
      const handleVerifyOtpformbile = async () => {
        try {
          const response = await axios.put("http://68.178.169.226:12080/api/v1/seller/forgotpassword", {
            otp,
            newpassword:password,
            mobile:mobile,
          });
      
          // Assuming your API returns a token on success
          if (response.data.status === "Success") {
            console.log("Navigating to AdminLogin");
            // Navigate to AdminLoginPage
            navigate("/AdminLogin");
          }
         
        } catch (error) {
          console.error("Error verifying OTP:", error);
        }
      };

    const handleResendOtp = () => {
        // Add logic to resend the OTP
        if (selectedOption === "email") {
          sendOtp();
        } else {
          sendOtpformobile();
        }
    };
    const handleNewPasswordChange = (e) => {
        const password = e.target.value;
        setPasswrd(password);
        setPasswordsMatch(password === confirmpasssword);
    };
    
    // Function to handle confirm password change and check if they match
    const handleConfirmPasswordChange = (e) => {
        const confirmpasssword = e.target.value;
        setConfirmpasssword(confirmpasssword);
        setPasswordsMatch(password === confirmpasssword);
    };
    return(
        <div className="wrapper">
            	<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Forgot Password</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
                            <Link to="/AdminLogin">
          <span><i className="mdi mdi-chevron-right"></i></span>Login
        </Link></p>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4></h4>

										<form onSubmit={handleSubmit}
										>
                                         
        <div className="form-group row">
          <div className="col-12">
            <div className="form-check form-check-inline" style={{ marginRight: "2px" }}>
              <input
                type="radio"
                name="options"
                id="emailOption"
                checked={selectedOption === "email"}
                onChange={() => handleOptionChange("email")}
                className="form-check-input"
                style={{ width: "16px", height: "16px", borderRadius: "50%" }}
              />
              <label htmlFor="emailOption" className="form-check-label">Email</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                name="options"
                id="mobileOption"
                checked={selectedOption === "mobile"}
                onChange={() => handleOptionChange("mobile")}
                className="form-check-input"
                style={{ width: "16px", height: "16px", borderRadius: "50%" }}
              />
              <label htmlFor="mobileOption" className="form-check-label">Mobile</label>
            </div>
          </div>
        </div>

                                             <div className="form-group row">
          <label htmlFor="text" className="col-12 col-form-label">
            {selectedOption === "email" ? "Email" : "Mobile"}
          </label>
          <div className="col-12">
            <input
              id="text"
              name="text"
              className="form-control here slug-title"
              type={selectedOption === "email" ? "text" : "tel"}
              placeholder={
                selectedOption === "email"
                  ? "Enter your email"
                  : "Enter your mobile number"
              }
              autoComplete="off"
              value={selectedOption === "email" ? email : mobile}
      onChange={(e) => {
        if (selectedOption === "email") {
          setEmail(e.target.value);
        } else {
          setMobile(e.target.value);
        }
      }}
            />
          </div>
        </div>
        <div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary"  >Submit</button>
												</div>
                                                {otpResponseMessage && <div className="response-message">{otpResponseMessage}</div>}
                                                {otpResponseMessageformobile && <div className="response-message">{otpResponseMessageformobile}</div>}
											</div>

        


										
                                            {showOtpFieldforlogin && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter Password"
                          value={password}
                          onChange={handleNewPasswordChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter ConfirmPassword"
                          value={confirmpasssword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                      {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleVerifyOtp}
                          style={{ width: "100%" }}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group mb-0">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleResendOtp}
                          style={{ width: "100%" }}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div> */}
                  </div>
                </>
              )}

              {showOtpFieldforloginformobile && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter Password"
                          value={password}
                          onChange={handleNewPasswordChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          className="input-text"
                          placeholder="Enter ConfirmPassword"
                          value={confirmpasssword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                      {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleVerifyOtpformbile}
                          style={{ width: "100%" }}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group mb-0">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleResendOtp}
                          style={{ width: "100%" }}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div> */}
                  </div>
                </>
              )}


										</form>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 
            </div>
    );
}
export default ForgotPassword;