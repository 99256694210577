import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "../../style.scss";
import "../../riot_admin.scss";
import "../../materialdesignicons.scss";
import Logo from "../../../src/images/megashop.png";
import { jwtDecode } from "jwt-decode";

const menuItems = [
  // { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];
const SideBar = () => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
    banner: false,
    offer: false,
    cinfo: false,
    cdetails: false,
    cminfo: false,
    coupon: false,
    pincode: false,
    deliverycharges: false,
    showpincode: false,
  });
  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  function handleLogout() {
    // Navigate to the login page
    window.location.href = "/AdminLogin";
  }
  useEffect(() => {
    const token = sessionStorage.getItem("tokenforseller"); // Assuming you store the token in localStorage

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token has expired
        setIsTokenExpired(true);
        sessionStorage.removeItem("tokenforseller");
        navigate("/AdminLogin");
      } else {
        // Token is still valid
        setIsTokenExpired(false);
      }
    }
  }, []);
  return (
    <div class="ec-left-sidebar ec-bg-sidebar">
      <div id="sidebar" class="sidebar ec-sidebar-footer">
        <div class="ec-brand">
          <div className="navbar-logo ">
            <img
              id="sidebar-toggler"
              className="sidebar-toggle"
              src={Logo}
              style={{ width: "120px" }}
            ></img>
          </div>
          {/* <span class="ec-brand-name text-truncate">Shopeasey</span> */}
        </div>

        {/* <!-- begin sidebar scrollbar --> */}
        <div class="ec-navigation" data-simplebar>
          {/* <!-- sidebar menu --> */}
          <ul class="nav sidebar-inner" id="sidebar-menu">
            {/* <!-- Dashboard --> */}
            {menuItems.map((item, index) => (
              <li key={index}>
                <a className="sidenav-item-link" href={item.link}>
                  <i className={item.icon}></i>
                  <span className="nav-text">{item.text}</span>
                </a>
              </li>
            ))}
            {/* <!-- Vendors --> */}
            {/* <li className={`has-sub ${isSubMenuOpen.vendors ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('vendors')}>
          <i className="mdi mdi-account-group-outline"></i>
          <span className="nav-text">Vendors</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.vendors ? 'show' : ''}`}>
          <ul className="sub-menu" id="vendors" data-parent="#sidebar-menu">
            <li className="">
              <a className="sidenav-item-link" href="vendor-card">
                <span className="nav-text">Vendor Grid</span>
              </a>
            </li>

									<li class="">
										<a class="sidenav-item-link" href="vendor-list">
											<span class="nav-text">Vendor List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="vendor-profile">
											<span class="nav-text">Vendors Profile</span>
										</a>
									</li>
								</ul>
							</div>
						</li> */}

            {/* <!-- Users --> */}
            {/* <li className={`has-sub ${isSubMenuOpen.user ? 'expand' : ''}`}>
        <a className="sidenav-item-link" href="javascript:void(0)" onClick={() => toggleSubMenu('user')}>
          <i className="mdi mdi-account-group"></i>
          <span className="nav-text">Users</span> <b className="caret"></b>
        </a>
        <div className={`collapse ${isSubMenuOpen.user ? 'show' : ''}`}>
          <ul className="sub-menu" id="users" data-parent="#sidebar-menu">
                      <li>
                        <a className="sidenav-item-link" href="user-card">
                          <span className="nav-text">User Grid</span>
                        </a>
                      </li>

									<li class="">
										<a class="sidenav-item-link" href="user-list">
											<span class="nav-text">User List</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="user-profile">
											<span class="nav-text">Users Profile</span>
										</a>
									</li>
								</ul>
							</div>
							 <hr/>
						</li> */}

            <li
              className={`has-sub ${isSubMenuOpen.showpincode ? "expand" : ""}`}
            >
              <a href="SellerDashBoard" className="sidenav-item-link">
                <i className="mdi mdi-monitor-dashboard"></i>
                <span className="nav-text">Dash Board</span>
              </a>
            </li>

            {/* <!-- Products --> */}
            <li className={`has-sub ${isSubMenuOpen.products ? "expand" : ""}`}>
              <a
                className="sidenav-item-link"
                href="javascript:void(0)"
                onClick={() => toggleSubMenu("products")}
              >
                <i className="mdi mdi-palette-advanced"></i>
                <span className="nav-text">Products</span>
                <b className="caret"> </b>
              </a>
              <div
                className={`collapse ${isSubMenuOpen.products ? "show" : ""}`}
              >
                <ul
                  className="sub-menu"
                  id="vendors"
                  data-parent="#sidebar-menu"
                >
                  <li className="">
                    <a className="sidenav-item-link" href="ProductAddforVendor">
                      <span className="nav-text">Add Product</span>
                    </a>
                  </li>
                  {/* <li className="">
                        <a className="sidenav-item-link" href="product-edit">
                          <span className="nav-text">Edit Product</span>
                        </a>
                      </li> */}
                  <li class="">
                    <a class="sidenav-item-link" href="ListProductforVendor">
                      <span class="nav-text">List Product</span>
                    </a>
                  </li>
                  {/* <li class="">
										<a class="sidenav-item-link" href="product-grid">
											<span class="nav-text">Grid Product</span>
										</a>
									</li>
									<li class="">
										<a class="sidenav-item-link" href="product-detail">
											<span class="nav-text">Product Detail</span>
										</a>
									</li> */}
                </ul>
              </div>
            </li>
            {/* <!-- Orders --> */}
            <li className={`has-sub ${isSubMenuOpen.orders ? "expand" : ""}`}>
              <a
                className="sidenav-item-link"
                href="javascript:void(0)"
                onClick={() => toggleSubMenu("orders")}
              >
                <i className="mdi mdi-account-group-outline"></i>
                <span className="nav-text">Orders</span>{" "}
                <b className="caret"></b>
              </a>
              <div className={`collapse ${isSubMenuOpen.orders ? "show" : ""}`}>
                <ul
                  className="sub-menu"
                  id="vendors"
                  data-parent="#sidebar-menu"
                >
                  <li className="">
                    <a className="sidenav-item-link" href="NewOrderforVendor">
                      <span className="nav-text">New Order</span>
                    </a>
                  </li>
                  {/* <li class="">
										<a class="sidenav-item-link" href="order-history">
											<span class="nav-text">Order History</span>
										</a>
									</li> */}
                  {/* <li class="">
										<a class="sidenav-item-link" href="order-detail">
											<span class="nav-text">Order Detail</span>
										</a>
									</li> */}
                  {/* <li class="">
										<a class="sidenav-item-link" href="invoice">
											<span class="nav-text">Invoice</span>
										</a>
									</li> */}
                </ul>
              </div>
            </li>
            
            <li
              className={`has-sub ${isSubMenuOpen.showpincode ? "expand" : ""}`}
            >
              <a href="SocialMediaIconsforSeller" className="sidenav-item-link">
                <i className="mdi mdi-creative-commons"></i>
                <span className="nav-text">Icons</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
