import React, { useState, useEffect } from "react";
import SideBar from "../sidebar";
import Header from "../Header";
import { useNavigate, Link } from 'react-router-dom';
import { Input, Button, Modal, Switch, Table,notification } from 'antd';
import EditCoupon from '../../src/coupon/EditCoupon'; 
import axios from 'axios';
import moment from 'moment';
const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
    return (
      <Modal
        visible={visible}
        title="Confirm Deletion"
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={onConfirm}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this coupon?</p>
      </Modal>
    );
  };
const ListCoupons = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [headerData, setHeaderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        const filtered = data.filter(item =>
          item.couponname.toLowerCase().includes(value.toLowerCase()) ||
          item.couponcode.toLowerCase().includes(value.toLowerCase()) ||
          item.minimumordervalueamount.toLowerCase().includes(value.toLowerCase()) ||
          (item.coupondescription && item.coupondescription.toLowerCase().includes(value.toLowerCase())) ||(item.couponusage && item.couponusage.toString().includes(value.toLowerCase())) ||(item.totalusage && item.totalusage.toString().includes(value.toLowerCase()))||
          (item.couponamount && item.couponamount.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredData(filtered);
      };
      useEffect(() => {
        if (!searchQuery) {
          setFilteredData(data);
        }
      }, [searchQuery, data]);
      const tableDataSource = searchQuery ? filteredData : data;
        useEffect(() => {
          if (!token) {
            // Redirect to the login page if the token is not present
            navigate('/AdminLogin');
            return;
          }
          fetchData();
        }, []);
        const fetchData = async () => {
            try {
              setLoading(true);
              const response = await axios.post('http://68.178.169.226:12080/api/v1/coupon/findAll', {}, {
                headers: {
                    'Authorization': 'Bearer ' +token,
                  'Content-Type': 'application/json',
                },
              });
              if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return; 
              }
              const data = response.data;
              setData(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }finally{
              setLoading(false);
            }
          };
          
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
      const handleDeleteCategory = (couponId) => {
        setSelectedCategory(couponId);
        setShowDeleteModal(true);
      };
      const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
      const handleSaveEdit = async (editedData) => {
        try {
          editedData.categoryid = selectedCategory.couponid;
          editedData.categoryname = selectedCategory.couponname;
          delete editedData.fileModel;
    
          const response = await axios.post(
            `http://68.178.169.226:12080/api/v1/coupon/addedit`,
            editedData,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
              },
            }
          );
    
          if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/AdminLogin');
            return;
          }
          notification.success({
            message: response.data.description,
            duration: 3, // Duration in seconds
          });
          fetchData();
          couponHeader();
          if (response.status === 200) {
            console.log('Subcategory updated successfully');
          } else {
            console.error('PUT request failed');
          }
        } catch (error) {
          console.error('Error updating data:', error);
        } finally {
          handleCloseEditModal(); // Close the modal whether the request succeeds or fails
        }
      };
    
      const handleDeleteCategoryConfirmed = async () => {
		try {
		  const response = await axios.post(
			`http://68.178.169.226:12080/api/v1/coupon/delete`,
			{
                couponid: selectedCategory,
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 200) {
        notification.success({
          message: 'Coupon deleted successfully',
          duration: 3, // Duration in seconds
        });
			fetchData();
      couponHeader();
		  } else {
			console.error('Failed to delete Coupon');
      notification.error({
				message: data.description,
				duration: 3, // Duration in seconds
			  });
		  }
		} catch (error) {
		  console.error('Error deleting category:', error);
		} finally {
		  setShowDeleteModal(false);
		}
	  };
        const couponHeader = async () => {
            try{
              setLoading(true);
                const response = await fetch('http://68.178.169.226:12080/api/v1/coupon/couponheader',{
                    method: 'POST',
                      headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                      'Content-Type': 'application/json',
                      },
                });
                const data = await response.json();
                setHeaderData(data);
            }
            catch (error) {
                console.error('Error fetching Header:', error);
            }finally{
              setLoading(false);
            }
        }
        useEffect(() => {
        couponHeader();
      }, [])
      const couponHeaderTable = async (useType) => {
        try{
          const formData = new FormData();
          formData.append("use",useType);
          const response = await fetch('http://68.178.169.226:12080/api/v1/coupon/findbyusage',{
            method: 'POST',
            body : formData,
              headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
          });
          const data= await response.json();
          setData(data);
        }catch (error){
          console.error('Error fetching Header Table data:', error);
        }
      }
      const handleHeaderClick = (useType) =>{
        couponHeaderTable(useType);
      }
      const columns =[
            {
            title: 'Name',
      dataIndex: 'couponname',
      key: 'couponname',
            },
            {
                title: 'Code',
                dataIndex: 'couponcode',
                key: 'couponcode', 
            },
            {
                title: 'Description',
      dataIndex: 'coupondescription',
      key: 'coupondescription',
            },
            {
                title: 'Amount',
      dataIndex: 'couponamount',
      key: 'couponamount',
            },
            {
                title: 'Minimum Order Value',
      dataIndex: 'minimumordervalueamount',
      key: 'minimumordervalueamount',
            },
            {
                title: 'User Limit',
      dataIndex: 'couponusage',
      key: 'couponusage',
            },
            {
              title: 'Total Usage',
    dataIndex: 'totalusage',
    key: 'totalusage',
          },
            {
                title: 'First Time',
      dataIndex: 'firsttime',
      key: 'firsttime' ,
      render: (firsttime) => (firsttime ? 'Yes' : 'No')
            },
            {
              title: 'Unlimited',
    dataIndex: 'unlimited',
    key: 'unlimited' ,
    render: (unlimited) => (unlimited ? 'Yes' : 'No')
          },
            {
                title: 'Start Date',
      dataIndex: 'validstart',
      key: 'validstart',
      render: (validstart) => moment(validstart).format('YYYY-MM-DD hh:mm A')
            },
            {
                title: 'End Date',
      dataIndex: 'validend',
      key: 'validend',
      render: (validend) => moment(validend).format('YYYY-MM-DD hh:mm A')
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (text, row) => (
                  <div className="btn-group">
                    <a
                      href="#"
                      className="btn btn-outline-success"
                      style={{ width: '100px' }}
                      onClick={() => handleEditCategory(row)}
                    >
                      Edit
                    </a>
                    <a
                      href="#"
                      className="btn btn-outline-danger"
                      style={{ width: '100px' }}
                      onClick={() => handleDeleteCategory(row.couponid)}
                    >
                      Delete
                    </a>
                  </div>
                ),
              },
          ]
    return(
        <div className="wrapper">
             <SideBar />
             <div className="ec-page-wrapper">
             <Header />
             <div className="ec-content-wrapper">
             <div className="content" >
             <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Coupon</h1>
              </div>
              <div>
                <Link to="/coupon" className="btn btn-primary">
                  Add Coupon
                </Link>
              </div>
            </div>
            {loading  ? (
  <div className="container-fluied">
    <div className="row">
      <div className="muti-col-loader"></div>
    </div>
  </div>
) : (     
<>
<div className="row">
                <div className="col-2">
                <h5 onClick={() => handleHeaderClick('unused')}>Unused Coupon:{headerData && headerData.totalNoOfUnusedCoupon}</h5>
                </div>
                <div className="col-2">
                <h5 onClick={() => handleHeaderClick('all')}>Total Coupon:{headerData && headerData.totalNoOfCoupon}</h5>
                </div>
                <div className="col-2">
                <h5 onClick={() => handleHeaderClick('used')}>Used Coupon:{headerData && headerData.totalNoOfUsedCoupon}</h5>
                </div>
                <div className="col-2">
                <h5 onClick={() => handleHeaderClick('active')}>Active Coupons:{headerData && headerData.totalNoOfActiveCoupons}</h5>
                </div>
                <div className="col-2">
                <h5 onClick={() => handleHeaderClick('inactive')}>Inactive Coupons:{headerData && headerData.totalNoOfInactiveCoupons}</h5>
                </div>
                <div className="col-2">
                </div>
            </div>
            <div className="row">
            <div className="col-12">
            <div className="card card-default">
            <div className="card-body">
            <Input
  placeholder="Search "
  value={searchQuery}
  onChange={handleSearch}
  style={{ width: 200, marginBottom: 10 }}
/>
<Table
                      columns={columns}
                      dataSource={tableDataSource}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '25', '50', '100'],
                      }}
                      scroll={{ x: 'max-content' }}
                    />
            </div>
            </div>
            </div>
            </div>
            </>
)}

             </div>
             </div>
             {selectedCategory && (
  <EditCoupon
    category={selectedCategory}
    show={showEditModal} 
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
	fetchData={fetchData}
  />
)}
{selectedCategory && (
          <DeleteCategoryModal
            visible={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteCategoryConfirmed}
          />
        )}
                </div>
            </div>
    );
}
export default ListCoupons;