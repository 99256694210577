import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";


const promise = loadStripe("pk_live_51OChFxI0wQSu3P50fqcaBLffjOwFTqVdBIus13MxZgOredCuCXatXVsugcMUfO9kh4wqYMCNiPSm4cqhfpPm7BD800jc8ESOGf");
// Initialize Stripe Elements
export default function Stripe({clientSecret, isSucceeded}) {

  return (
    <>
      {clientSecret && promise && (
        <Elements stripe={promise} options={{ clientSecret }}>
          <CheckoutForm  isSucceeded ={isSucceeded} />
        </Elements>
      )}
    </>
  );
}