import React from 'react';
import './header.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from './../images/logo-web.jpg';
const LoginHeader = () => {
	const location = useLocation();
	const showSellerRegistration = location.pathname !== '/seller';
  return (
    <header className="header-middle" >
      
	  <div className='container d-flex justify-content-between'>
		<div className='logo'>
                <img src={Logo} /></div>
		<div className='login-btn d-flex align-items-center'>
			{showSellerRegistration ? (
		<Link to="/seller">Seller Registration</Link>
		) : (
			<Link to="/AdminLogin">Login</Link>
		)}
		</div>
			

	  </div>

    </header>
  );
};

export default LoginHeader;
