import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import { Button, Modal, DatePicker,notification  } from 'antd';
import Header from '../Header';

  const Coupon = () => {
	const token = localStorage.getItem('token');
	  const navigate = useNavigate();
	  const [couponname, setCouponname] =useState('');
	  const [couponcode, setCouponcode] =useState('');
	  const [coupondescription, setCoupondescription] =useState('');
	  const [couponamount, setCouponamount] =useState('');
	  const [minimumordervalueamount, setMinimumordervalueamount] =useState('');
	  const [couponusage, setCouponusage] =useState('');
	  const [totalusage, setTotalusage] =useState('');
	  const [firsttime, setFirsttime] =useState('');
	  const [unlimited, setUnlimited] =useState('');
	  const [validstart, setValidstart] =useState('');
	  const [validend, setValidend] =useState('');
	const onFinish = async (e) => {
		e.preventDefault(); 
		const token = localStorage.getItem('token');
		try {
		  const response = await fetch("http://68.178.169.226:12080/api/v1/coupon/addedit", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
                couponname,
                couponcode,
                coupondescription,
                couponamount : parseInt(couponamount),
                minimumordervalueamount: parseInt(minimumordervalueamount),
                couponusage : parseInt(couponusage),
                firsttime,
				unlimited,
                validstart,
                validend,
				totalusage : parseInt(totalusage),
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) { 
			navigate('/AdminLogin');
			return; 
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			notification.success({
				message: data.description,
				duration: 3, // Duration in seconds
			  });
			navigate("/ListCoupons");
			couponname('');
            setCouponcode('');
            setCoupondescription('');
            setCouponamount('');
            setMinimumordervalueamount('');
            setCouponusage('');
            setFirsttime('');
			setUnlimited('');
            setValidstart('');
            setValidend('');
			if (data.token) {
			  localStorage.setItem('token', token);
			}
		  } else {
			console.error("POST request failed");
			notification.error({
				message: "Failed to add banner",
				duration: 3, // Duration in seconds
			  });
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		}
	  };
	return (
		<div className="wrapper">
		<SideBar/>

		<div class="ec-page-wrapper">
			<Header/>
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Coupon</h1>
					</div>
					<div className="row">
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add New Coupon</h4>
										<form
										onSubmit={onFinish}
										>

											<div className="form-group row">
												<label for="text" className="col-12 col-form-label">Name</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title" type="text" value={couponname} onChange={(e) => setCouponname(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div className="form-group row">
												<label for="slug" className="col-12 col-form-label">Code</label> 
												<div className="col-12">
													<input id="slug" name="slug" className="form-control here set-slug" type="text" value={couponcode} onChange={(e) => setCouponcode(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div className="form-group row">
												<label className="col-12 col-form-label">Description</label> 
												<div className="col-12">
													<textarea id="sortdescription" name="sortdescription" cols="40" rows="2" className="form-control" value={coupondescription} onChange={(e) => setCoupondescription(e.target.value)}></textarea>
												</div>
											</div> 

											<div className="form-group row">
												<label className="col-12 col-form-label">Amount</label>
												<div className="col-12">
													<input type="number" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={couponamount} onChange={(e) => setCouponamount(e.target.value)} autoComplete="off" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-12 col-form-label">Minimum Order Value</label>
												<div className="col-12">
													<input type="number" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={minimumordervalueamount} onChange={(e) => setMinimumordervalueamount(e.target.value)} autoComplete="off" />
												</div>
											</div>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">User Limit</label>
												<div className="col-12">
													<input type="number" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={couponusage} onChange={(e) => setCouponusage(e.target.value)} autoComplete="off" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-12 col-form-label">Total Usage</label>
												<div className="col-12">
													<input type="number" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={totalusage} onChange={(e) => setTotalusage(e.target.value)} autoComplete="off" />
												</div>
											</div>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">First Time<span>(Only for First User)</span></label>
												<div className="col-12">
                                                <input type="checkbox" id="firstTimeCheckbox" checked={firsttime} onChange={(e) => setFirsttime(e.target.checked)} />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-12 col-form-label">Unlimited Times</label>
												<div className="col-12">
                                                <input type="checkbox" id="unlimitedTimeCheckbox" checked={unlimited} onChange={(e) => setUnlimited(e.target.checked)} />
												</div>
											</div>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">Start Date</label>
												<div className="col-12">
                                                <DatePicker
    className="form-control"
    value={validstart}
    showTime 
    format="YYYY-MM-DD hh:mm A" 
        onChange={(date) => setValidstart(date)} 
/>
												</div>
											</div>
                                             <div className="form-group row">
												<label className="col-12 col-form-label">End Date</label>
												<div className="col-12">
                                                <DatePicker
                                                    className="form-control"
                                                    value={validend}
                                                    showTime 
                                                    format="YYYY-MM-DD hh:mm A" 
                                                    onChange={(date) => setValidend(date)} 
                                                />
												</div>
											</div> 
											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div> 
					</div>
				</div>
			</div> 

	</div>
	</div>
  );
}

export default Coupon;
