import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table, Input, Button, Select} from "antd"; // Import Input and Button for filtering
import '@ant-design/cssinjs';
import '../../../style.scss';
import '../../../riot_admin.scss';
import SideBar from '../../../vendor/sidebar/index';
import VendorHeader from '../../../../src/vendor/header';

const { Search } = Input;
const { Option } = Select;

const NewOrderforVendor = () => {
  const token = localStorage.getItem('token');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // State for filtered data
  const [searchText, setSearchText] = useState('');
  const [clientId, setClientId] = useState(null);
  const storeOrderIDInSession = (orderid) => {
    sessionStorage.setItem('selectedOrderID', orderid);
  };
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'http://68.178.169.226:12080/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setClientId(responseData.id); // Store the client ID in state
          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    if (clientId) {
      const fetchSubData = async () => {
        try {
          const formData = new FormData();
          formData.append('sellerid', clientId);
  
          const response = await axios.post(
            'http://68.178.169.226:12080/api/v1/order/getbyselleridrecent',
            formData,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
              },
            }
          );
  
          if (response.status === 401 || response.status === 404) {
            // navigate("/login");
            return;
          }
          setOrders(response.data)
          console.log('Response data:', response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchSubData();
    }
  }, [clientId]);
  
 
  return (
    <div className="wrapper">
      <SideBar />
      <VendorHeader />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2">
            <h1>Orders</h1>
          </div>
          <div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Order Number</th>
													<th>Total</th>
													<th>Subtotal</th>
													<th>Payment Method</th>
													<th>Order Date</th>
												</tr>
											</thead>
											<tbody>
                        {orders.map((category) => (
                          <tr key={category.orderid}>
                            <td>{category.ordernumber}</td>
                            <td>{category.total}</td>
                            <td>{category.subtotal}</td>
                            <td>{category.paymentmethod}</td>
                            <td>{category.createDate}</td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
        </div>
      </div>
    </div>
  );
}

export default NewOrderforVendor;
