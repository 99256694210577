import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import { Input } from "antd";
import Header from "./Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditProductModal from "./EditProduct";
import { Select, Form, Upload, Button, DatePicker } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

const DuplicateProductAdd = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { productData } = location.state || {};
  const navigate = useNavigate();
  const categoryName = productData ? productData.categoryname : "N/A";
  const subcategoryname = productData ? productData.subcategoryname : "N/A";
  const subsubcategoryname = productData ? productData.subsubcategoryname : "N/A";
  const type = productData ? productData.type : "N/A";
  const producttags = productData ? productData.producttags : "N/A";
  const productname = productData ? productData.productname : "N/A";
  const slug = productData ? productData.slug : "N/A";
  const size = productData ? productData.size : "N/A";
  const mrpprice = productData ? productData.mrpprice : "N/A";
  const sellingprice = productData ? productData.sellingprice : "N/A";
  const quantity = productData ? productData.quantity : "N/A";
  const color = productData ? productData.color : "N/A";
  const colorname = productData ? productData.colorname : "N/A";
  const weight = productData ? productData.weight : "N/A";
  const storage = productData ? productData.storage : "N/A";
  const options = productData ? productData.options : "N/A";
  const productposition = productData ? productData.productposition : "N/A";
  const productunit = productData ? productData.productunit : "N/A";
  const offerprice = productData ? productData.offerprice : "N/A";
  const offerstart = productData ? productData.offerstart : "N/A";
  const offerend = productData ? productData.offerend : "N/A";
  const onFinish = async (e) => {
    e.preventDefault(); 
    try {
      // TODO: Replace with actual API call
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/product/add",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productid: productData ? productData.productid : null,
          }),
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }

      if (response.ok) {
        const data = await response.json();
         alert(data.description);
       
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <Header />
        <div className="ec-content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Add Product</h1>
                <p className="breadcrumbs">
                  <span>
                    <a href="index.html">Home</a>
                  </span>
                  <span>
                    <i className="mdi mdi-chevron-right"></i>
                  </span>
                  Product
                </p>
              </div>
              <div>
                <Link to="/product-list" className="btn btn-primary">
                  {" "}
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom">
                    {/* <h2>Add Product</h2> */}
                  </div>

                  <div className="card-body">
                    <div className="row ec-vendor-uploads">
                      <div className="col-lg-8">
                        <div className="ec-vendor-upload-detail">
                        <form onSubmit={onFinish}>
                          <div className="row">
                          <div className="col-md-3">
                                <label className="form-label">
                                 Category
                                </label>
                                <div>{categoryName}</div>
                              </div>
                          <div className="col-md-3">
                                <label className="form-label">
                                 Sub Category
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div>{subcategoryname}</div>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label">
                                 Subsub Category
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div>{subsubcategoryname}</div>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label">
                                  Group Type
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div>{type}</div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Code
                                </label>
                                <div>{producttags}</div>
                              </div>
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Name
                                </label>
                                <div>{productname}</div>
                              </div>
                              </div>
                              <div className="col-md-12">
                              <label
                                for="slug"
                                className="col-12 col-form-label"
                              >
                                Slug
                              </label>
                              <div>{slug}</div>
                              </div>
                              <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">MRP Price</label>
                                <div>{mrpprice}</div>
                              </div>
                              <div className="col-md-4">
  <label className="form-label">Selling Price</label>
  <div>{sellingprice}</div>
  </div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">Quantity</label>
                              <div>{quantity}</div>
                            </div>
                            
                            <div className="col-md-12">
                              <label className="form-label" name="uploadfile">
                                Upload Images
                              </label>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label" name="previewimage">
                                Preview Images
                              </label>
                            </div>
                            <div className="card-header card-header-border-bottom">
                              <h2>Additional Inputs</h2>
                            </div>
                            <div className="row">
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Color</label>
                                <div>{color}</div>
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">
                                  Colour Name
                                </label>
                                <div>{colorname}</div>
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Weight</label>
                                <div>{weight}</div>
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Unit</label>
                                <div>{productunit}</div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                              <label className="form-label">Size</label>
                              <div>{size}</div>
                              </div>
                              <div className="col-md-6 mb-25">
                                <label className="form-label">Storage</label>
                                <div>{storage}</div>
                              </div>
                              <div className="col-md-12 mb-25">
                              <label className="form-label">Options</label>
                              <div>{options}</div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Product Position
                              </label>
                              <div>{productposition}</div>
                            </div>
                              </div>
                              {/* <div>
                                <h3 className="form-label">CountDown Sale</h3>
                                <div className="col-md-12">
                                  <label
                                    for="slug"
                                    className="col-12 col-form-label"
                                  >
                                    Offer Price
                                  </label>
                                  <div>{offerprice}</div>
                                  </div>
                                  <div className="col-md-12">
                                  <label
                                    for="slug"
                                    className="col-12 col-form-label "
                                  >
                                    Offer Period Start
                                  </label>
                                  <div>{offerstart}</div>
                                  </div>
                                  <div className="col-md-12">
                                  <label
                                    for="slug"
                                    className="col-12 col-form-label"
                                  >
                                    Offer Period End
                                  </label>
                                  <div>{offerend}</div>
                                  </div>
                                </div> */}
                                <div className="col-md-12">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                            </form>
                      </div>       </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default DuplicateProductAdd;
