import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import '../../style.scss';
import '../../riot_admin.scss';
import SideBar from '../sidebar/index';
import { Input, Button, Modal, Table,notification } from 'antd';
import VendorHeader from '../../../src/vendor/header';
import EditProductModalforVendor from '../product/editproduct';
const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
	  <Modal
		visible={visible}
		title="Confirm Deletion"
		onCancel={onCancel}
		footer={[
		  <Button key="cancel" onClick={onCancel}>
			Cancel
		  </Button>,
		  <Button key="delete" type="primary" onClick={onConfirm}>
			Delete
		  </Button>,
		]}
	  >
		<p>Are you sure you want to delete this product?</p>
	  </Modal>
	);
  };
const ListProductforVendor = () => {
  const token = localStorage.getItem('token');
	const navigate = useNavigate();
	  const [data, setData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryList, setCategoriesList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(true);
	  const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [errorvalueforimg, setErrorvalueforimg] = useState(null);
    const [selectedProductTypeId, setSelectedProductTypeId] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
      const [pageNumbers, setPageNumbers] = useState([]);
    function stripHtmlTags(htmlString) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;
      return tempElement.textContent || tempElement.innerText;
    }
    
    useEffect(() => {
      const fetchClientDetails = async () => {
        try {
          const response = await fetch(
            'http://68.178.169.226:12080/api/v1/login/getuserdetails',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
              },
            }
          );
  
          if (response.ok) {
              const responseData = await response.json();
              setClientId(responseData.id); // Store the client ID in state
            } else {
              console.error('Error fetching client details');
            }
        } catch (error) {
          console.error("Error checking authentication:", error);
        }
      };
  
      fetchClientDetails();
    }, []);

    useEffect(() => {
      fetchData(0);
    }, [clientId]);
    
    const fetchData = async (page) => {
      if (clientId) {
        try {
          const response = await axios.put(
            "http://68.178.169.226:12080/api/v1/product/getforseller",
            {
              sellerid: clientId,
              page:page,
      noofproducts:10,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
    
    
          const data = response.data.data;
          setData(response.data.data);
          setCurrentPage(response.data.Currentpageno)
          setTotalPages(response.data.Totalpageno)
          if(response.data.Totalpageno <= 1) {
            setPageNumbers([]);
          }else{
    
          const numbersArray = Array.from({ length: response.data.Totalpageno }, (_, index) => index + 1);
          setPageNumbers(numbersArray);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    const handleEditCategory = async(product) => {
      const fileids = product.fileModel!=null ? product.fileModel.map(file => file.fileid) : [];
      const previewfileids = product.previewimg != null ? product.previewimg.fileid : "";

      // Store fileids in sessionStorage
      sessionStorage.setItem('fileids', JSON.stringify(fileids));
      sessionStorage.setItem('previewfileids', previewfileids);
      setSelectedProduct(product);
      try {
        const response = await axios.put('http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory', {},{
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          navigate('/AdminLogin');
          return; // Exit early to prevent further logic execution
        }
        const selectedCategory = response.data.find(category => category.categoryid === product.categoryid);
        if (selectedCategory) {
          setSelectedProductTypeId(selectedCategory.producttypeid || '');
          console.log("yhn",selectedCategory.producttypeid)
          setShowEditModal(true);
        } else {
          console.error('Category not found for product:', product);
        }
      }catch (error) {
        console.error('Error fetching category data:', error);
      }
      };
      const handleCloseEditModal = () => {
        setSelectedProduct(null);
        setShowEditModal(false);
        };
        // Function to close the edit modal
	  const handleSaveEdit = async (editedData) => {
      try {
        setLoading(true);
        editedData.categoryid = selectedCategory.categoryid;
        editedData.productid = selectedProduct.productid;
        editedData.subcategoryid = selectedProduct.subcategoryid;
        editedData.categoryname = selectedCategory.categoryname;
        // Make an API request to update the subcategory data
        const response = await axios.post(
        `http://68.178.169.226:12080/api/v1/product/edit`,
        editedData,
        {
          headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
          'Content-Type': 'application/json',
          },
        }
        );
    
        if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return;
        }
        
  notification.success({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
        fetchData(currentPage);
        if (response.status === 200) {
        console.log('Subcategory updated successfully');
        // Optionally, you can update your component state or perform other actions here
        // window.location.reload();
        } else {
        console.error('PUT request failed');
        }
      } catch (error) {
        console.error('Error updating data:', error);
      } finally {
        setLoading(false);
        handleCloseEditModal(); // Close the modal whether the request succeeds or fails
      }
      };
  
    const handleDeleteCategoryConfirmed = async () => {
      try {
        setLoading(true);
    
        // Make an API request to delete the category
        const response = await axios.post(
        `http://68.178.169.226:12080/api/v1/product/delete`,
        {
          categoryid: selectedCategory,
        },
        {
          headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
          },
        }
        );
    
        if (response.status === 200) {
        // Refresh the page or update the category list
        fetchData(currentPage);
        } else {
        console.error('Failed to delete category');
        }
      } catch (error) {
        console.error('Error deleting category:', error);
      } finally {
        setLoading(false);
        setShowDeleteModal(false);
      }
      };
      const handleDeleteCategory = (productId) => {
        setSelectedCategory(productId);
        setShowDeleteModal(true);
        };
    useEffect(() => {
      if (clientId) {
        const fetchSubData = async () => {
          try {
            // Construct the URL with query parameters
            const apiUrl = `http://68.178.169.226:12080/api/v1/sellercategory/get`;
            const requestBody = {
              sellerid: clientId,
            };
  
            const response = await axios.post(apiUrl, requestBody, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
              },
            });
  
            if (response.status === 401 || response.status === 404) {
              // navigate("/login");
              return;
            }
  
            setCategoriesList(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
  
        fetchSubData();
      }
    }, [clientId]);

  const columns = [
    {
      title: 'Category',
      dataIndex: 'categoryname',
      key: 'categoryname',
      sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
    },
    {
      title: 'SubCategory',
      dataIndex: 'subcategoryname',
      key: 'subcategoryname',
    },
    {
      title: 'SubSubCategory',
      dataIndex: 'subsubcategoryname',
      key: 'subsubcategoryname',
    },
    {
      title: 'Product Name',
      dataIndex: 'productname',
      key: 'productname',
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    // {
    //   name: 'Slug',
    //   selector: 'slug',
    //   sortable: true,
    // },
    // {
    //   name: 'Description',
    //   selector: 'sortdescription',
    //   sortable: true,
    //   cell: (row) => (
    //     <div>{stripHtmlTags(row.sortdescription)}</div>
    //   ),
    // },
    
    {
      title: 'MRP Price',
      dataIndex: 'mrpprice',
      key: 'mrpprice',
      sorter: (a, b) => a.mrpprice - b.mrpprice,
    },
    {
      title: 'Selling Price',
      dataIndex: 'sellingprice',
      key: 'sellingprice',
      sorter: (a, b) => a.sellingprice - b.sellingprice,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    // {
    //   name: 'Weight',
    //   selector: 'weight',
    //   sortable: true,
    // },
    // {
    //   name: 'Size',
    //   selector: 'size',
    //   sortable: true,
    // },
    // {
    //   name: 'Storage',
    //   selector: 'storage',
    //   sortable: true,
    // },
    // {
    //   name: 'Colour',
    //   selector: 'color',
    //   sortable: true,
    // },
    // {
    //   name: 'Product Image',
    //   selector: 'productimage',
    //   sortable: true,
    //   cell: (row) => (
    //     <img
    //       src={row.productimage}
    //       alt="Product Image"
    //       style={{ maxWidth: '100px', maxHeight: '100px' }}
    //     />
    //   ),
    // },
    {
      title: 'Status',
      dataIndex: 'cancel',
      key: 'cancel',
      render : (text,row) => (
        <>
        {row.cancel == false&& (
         <p> Approve</p>
        )}
        {row.cancel == true && (
          <>
         <p> Cancelled</p>
         {row.cancelreason && (
          <p>Reason : {row.cancelreason}</p>
         )}
         </>
        )}
       {row.cancel == null && (
         <p> Waiting for admin approval</p>
        )}
        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, row) => (
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-outline-success" style={{width:"100px"}}
            onClick={(e) => {
              e.preventDefault();
              handleEditCategory(row); // Pass the entire row data to the edit function
            }}
          >
            Edit
          </a>
          {/* <a
            href="#"
            className="btn btn-outline-danger" style={{width:"100px"}}
            onClick={(e) => {
              e.preventDefault();
              // Add your delete logic here, you can access row data with `row`
              // For example, you can call a function to handle delete passing row.id
              handleDeleteCategory(row.productid);
            }}
          >
            Delete
          </a> */}
        </div>
      ),
    }
    
  ];
  const changePage = async (pageNumber) => {
    if (pageNumber >= 0 && pageNumber <= totalPages) {
      fetchData(pageNumber); // Call function to fetch all products
    }
  };
  const renderPageNumbers = () => {
    const maxVisiblePages = 6;
    const delta = Math.floor(maxVisiblePages / 2);
  
    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map((number) => (
        <li
          key={number}
          className={currentPage === number - 1 ? "selected-page" : ""}
          onClick={() => changePage(number - 1)}
        >
          {number}
        </li>
      ));
    }
  
    if (currentPage <= delta) {
      return (
        <>
          {pageNumbers.slice(0, maxVisiblePages - 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
          <li>...</li>
          <li onClick={() => changePage(totalPages - 1)}
          >
            {totalPages}
          </li>
        </>
      );
    }
  
    if (currentPage >= totalPages - delta) {
      return (
        <>
          <li
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => changePage(0)}
          >
            1
          </li>
          <li>...</li>
          {pageNumbers.slice(totalPages - maxVisiblePages + 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
        </>
      );
    }
    return (
      <>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => changePage(0)}
        >
          1
        </li>
        <li>...</li>
        {pageNumbers.slice(currentPage - delta, currentPage + delta).map((number) => (
          <li
            key={number}
            className={currentPage === number - 1 ? "selected-page" : ""}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => changePage(number - 1)}
          >
            {number}
          </li>
        ))}
        <li>...</li>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => changePage(totalPages - 1)}
        >
          {totalPages}
        </li>
      </>
    );
  };
  return (
    <div className="wrapper">
      {/* LEFT MAIN SIDEBAR */}
      <SideBar />

      {/* PAGE WRAPPER */}
      <div className="ec-page-wrapper">
        {/* Header */}
        <VendorHeader />
        {/* CONTENT WRAPPER */}
        <div className="ec-content-wrapper">
          <div className="content" >
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Product</h1>
                <p className="breadcrumbs">
                  <span>
                    <a href="index">Home</a>
                  </span>
                  <span>
                    <i className="mdi mdi-chevron-right"></i>
                  </span>
                  Product
                </p>
              </div>
              <div>
                <Link to="/ProductAddforVendor" className="btn btn-primary">
                  Add Product
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-body">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                     {totalPages > 1 && (
  <ul className="d-flex justify-content-center pagination">
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => changePage(currentPage - 1)}>
      {'<'}
    </li>
        {renderPageNumbers()}
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => changePage(currentPage + 1)}>
      {'>'}
    </li>
  </ul>
)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       {selectedProduct && (
  <EditProductModalforVendor
  show={showEditModal}
  onHide={handleCloseEditModal}
  onSave={handleSaveEdit}
  categoryList={categoryList}
  category={selectedCategory}
  product={selectedProduct}
  selectedProductTypeId={selectedProductTypeId} 
  />
)} 
{selectedCategory && (
            <DeleteCategoryModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteCategoryConfirmed}
            />
          )}
      </div>
    </div>
  );
};

export default ListProductforVendor;
