import React, { useState,useEffect } from "react";
import axios from "axios";
import { DatePicker  } from "antd";
const DashBoard = () => {
	const token = localStorage.getItem('token');
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [data, setData] = useState([]);
	const monthFormat = 'YYYY/MM';
    
    const dashBoardDetails = async () => {
        try {
			const formData = new FormData();
			formData.append('selectedMonth',selectedMonth.format(monthFormat))
            const response = await axios.post(
                'http://68.178.169.226:12080/api/v1/dashboard/admindashboard',
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
			setData(response.data)
        } catch (error) {
            console.error("Error", error);
        }
    };
    
    useEffect(() => {
        dashBoardDetails();
    }, [selectedMonth]);
    return(
        <div class="row">
                <DatePicker
                    picker="month"
                    value={selectedMonth}
                    onChange={date => setSelectedMonth(date)}
                    placeholder="Select month"
                    allowClear={false} 
					format={monthFormat}
                />

						<div class="col-xl-3 col-sm-6 p-b-15 lbl-card">
							<div class="card card-mini dash-card card-1">
								<div class="card-body">
									<h2 class="mb-1">{data.TotalCatagories}</h2>
									<p>Total Categories</p>
									<span class="mdi mdi-account-arrow-left"></span>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-sm-6 p-b-15 lbl-card">
							<div class="card card-mini dash-card card-2">
								<div class="card-body">
									<h2 class="mb-1">{data.TotalProducts}</h2>
									<p>Total Products</p>
									<span class="mdi mdi-account-clock"></span>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-sm-6 p-b-15 lbl-card">
							<div class="card card-mini dash-card card-3">
								<div class="card-body">
									<h2 class="mb-1">{data.ActiveProducts}</h2>
									<p>Active Products</p>
									<span class="mdi mdi-package-variant"></span>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-sm-6 p-b-15 lbl-card">
							<div class="card card-mini dash-card card-4">
								<div class="card-body">
									<h2 class="mb-1">{data.InactiveProducts}</h2>
									<p>Inactive Products</p>
									<span class="mdi mdi-currency-usd"></span>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-sm-6 p-b-15 lbl-card">
							<div class="card card-mini dash-card card-4">
								<div class="card-body">
									<h2 class="mb-1">{data.FeaturedProducts}</h2>
									<p>Featured Products</p>
									<span class="mdi mdi-currency-usd"></span>
								</div>
							</div>
						</div>
					</div>
    );
}
export default DashBoard;