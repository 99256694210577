import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import { Input,Form, Upload, Button, Modal, notification } from 'antd';
import Header from '../Header';
import EditCurrency from '../../src/currency/EditCurrency'; // Correct the import path
import { UploadOutlined } from '@ant-design/icons';
const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];
  const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
	  <Modal
		visible={visible}
		title="Confirm Deletion"
		onCancel={onCancel}
		footer={[
		  <Button key="cancel" onClick={onCancel}>
			Cancel
		  </Button>,
		  <Button key="delete" type="primary" onClick={onConfirm}>
			Delete
		  </Button>,
		]}
	  >
		<p>Are you sure you want to delete this category?</p>
	  </Modal>
	);
  };

  const CurrencyPage = () => {
	const token = localStorage.getItem('token');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const [loading, setLoading] = useState(true);
	  const navigate = useNavigate();
	  const [fileList, setFileList] = useState([]); // To store uploaded files
	  const [fileid, setUploadfiles] =useState([]); 
	  const [country, setCountry] =useState('');
	  const [mobileprefix, setCountryMobilecode] =useState('');
	  const [currencycode, setCurrencycode] =useState('');
	  const [symbol, setSymbol] =useState('');
      const [language, setlanguage] =useState('');
      const [languagecode, setlanguagecode] =useState('');
      const [mobilemaxlength, setMobilemaxlength] =useState('');
	  const [data, setData] = useState([]);
	  const [showDeleteModal, setShowDeleteModal] = useState(false);
	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	
	  const handleFileUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const formData = new FormData();
		formData.append("file", file);
	  
		try {
		  const response = await axios.post("http://68.178.169.226:12080/api/v1/fileupload/file",formData,{
			  onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
				  (progressEvent.loaded * 100) / progressEvent.total
				);
				onProgress({ percent: percentCompleted });
			  },
			  headers: {
				Authorization: 'Bearer ' + token, // Set the Authorization header with the token
				// 'Content-Type': 'application/json',
				'Content-Type': 'multipart/form-data',
			  },
			}
		  );
	  
		  if (response.status === 401 || response.status === 404 || !token) {
			// Redirect to the login page for 401 or 404 errors or if the token is not present
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  setUploadfiles((prevUploadFiles) => [...prevUploadFiles, response.data.organiserId]);
		  onSuccess("File uploaded");
		} catch (error) {
		  onError("File upload failed");
		}
	  };
	  

	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	 // Function to handle the delete operation
	 // ...
	 const handleDeleteCategoryConfirmed = async () => {
		try {
		  setLoading(true);
	
		  // Make an API request to delete the category
		  const response = await axios.post(
			`http://68.178.169.226:12080/api/v1/country/delete`,
			{
                countryselectionid: selectedCategory,
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 200) {
			notification.success({
				message: 'Country deleted successfully',
				duration: 3, // Duration in seconds
			  });
			fetchData();
		  } else {
			notification.success({
				message: 'Failed to delete country',
				duration: 3, // Duration in seconds
			  });
			console.error('Failed to delete category');
		  }
		} catch (error) {
		  console.error('Error deleting category:', error);
		} finally {
		  setLoading(false);
		  setShowDeleteModal(false);
		}
	  };
	  
  //
  
	
	  // Function to close the edit modal
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  const handleSaveEdit = (editedData) => {
		// Handle the save logic here and update the categories array with the edited data
		// You can use the editedData to make an API call to save the changes
		console.log('Edited data:', editedData);
		// After saving, you can update the categories array and close the modal
		// For example, update the categories array and close the modal as follows:
		// setCategories((prevCategories) => {
		//   const updatedCategories = prevCategories.map((category) => {
		//     if (category.id === selectedCategory.id) {
		//       return { ...category, ...editedData };
		//     }
		//     return category;
		//   });
		//   return updatedCategories;
		// });
		handleCloseEditModal();
	  };
	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("http://68.178.169.226:12080/api/v1/country/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

	const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		const cleanedFileId = String(fileid).replace(/,/g, '');


		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("http://68.178.169.226:12080/api/v1/country/addedit", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
                country,
                currencycode,
                symbol,
                language,
                languagecode,
                countryimageid: cleanedFileId,
				mobileprefix,
				mobilemaxlength,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) { 
			// Redirect to the login page for 401 or 404 errors
			// navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			notification.success({
				message: data.description,
				duration: 3, // Duration in seconds
			  });
			resetFormFields();
			fetchData();
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
			notification.error({
				message:data.description,
				duration: 3, // Duration in seconds
			  });
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };
	  
	const resetFormFields = async () => {
		setCountry('');
		setCountryMobilecode('');
		setCurrencycode('');
		setSymbol('');
		setlanguage('');
		setlanguagecode('');
		setMobilemaxlength('');
		setUploadfiles([]);
		setFileList([]);
	}


	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  const handleDeleteCategory = (categoryId) => {
		setSelectedCategory(categoryId);
		setShowDeleteModal(true);
	  };
	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		<div class="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Currency</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Currency</p>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add New Currency</h4>

										<form
										onSubmit={onFinish}
										>

											<div className="form-group row">
												<label for="text" className="col-12 col-form-label">Country</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title" type="text" value={country} onChange={(e) => setCountry(e.target.value)} autoComplete="off" />
												</div>
											</div>
											<div className="form-group row">
												<label for="slug" className="col-12 col-form-label">Country phnonenumber Code</label> 
												<div className="col-12">
													<input id="slug" name="slug" className="form-control here set-slug" type="text" value={mobileprefix} onChange={(e) => setCountryMobilecode(e.target.value)} autoComplete="off" />
													
												</div>
											</div>
											<div className="form-group row">
												<label for="text" className="col-12 col-form-label">Mobile No. Max Length</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title" type="text" value={mobilemaxlength} onChange={(e) => setMobilemaxlength(e.target.value)} autoComplete="off" />
												</div>
											</div>
											<div className="form-group row">
												<label for="slug" className="col-12 col-form-label">Currency Code</label> 
												<div className="col-12">
													<input id="slug" name="slug" className="form-control here set-slug" type="text" value={currencycode} onChange={(e) => setCurrencycode(e.target.value)} autoComplete="off" />
													
												</div>
											</div>

											<div className="form-group row">
												<label className="col-12 col-form-label">Symbol</label>
												<div className="col-12">
													<input type="text" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={symbol} onChange={(e) => setSymbol(e.target.value)} autoComplete="off" />
												</div>
											</div>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">Language</label>
												<div className="col-12">
													<input type="text" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={language} onChange={(e) => setlanguage(e.target.value)} autoComplete="off" />
												</div>
											</div>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">Language Code</label>
												<div className="col-12">
													<input type="text" className="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={languagecode} onChange={(e) => setlanguagecode(e.target.value)} autoComplete="off" />
												</div>
											</div>
											<div className="col-md-12">
													<label className="form-label" name="fileid">Upload Country Image</label>
													<Form.Item
															name="fileid"
														>
														<Upload
															customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
															fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}
															>
															<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
														</Upload>
													</Form.Item> 
													{/* <Upload
														customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
														fileList={fileList}
														onChange={({ fileList }) => setFileList(fileList)}
														>
											<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
										</Upload> */}
												</div>

											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Country</th>
													<th>Currency Code</th>
													<th>Symbol</th>
													<th>Country Image</th>
													{/* <th>Sub Categories</th>
													<th>Product</th>
													<th>Total Sell</th>
													<th>Status</th>
													<th>Trending</th> */}
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((category) => (
                          <tr key={category.countryselectionid}>
                            <td>{category.country}</td>
                            <td>{category.currencycode}</td>
                            <td>{category.symbol}</td>
							<img src={category.countryimage !=null ? category.countryimage:''} width="100" height="100"/>
                            <td>
							<div className="btn-group">
    <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleEditCategory(category);
      }}
    >
      Edit
    </a>
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.countryselectionid);
      }}
    >
      Delete
    </a> 
  </div>
							{/* <div className="btn-group">
  <button
    type="button"
    className="btn btn-outline-success"
  >
    Info
  </button>
  <button
    type="button"
    className="btn btn-outline-success dropdown-toggle dropdown-toggle-split"
    data-bs-toggle={isDropdownOpen ? 'dropdown' : ''}
    aria-haspopup="true"
    aria-expanded={isDropdownOpen}
    data-display="static"
	onClick={() => handleToggleDropdown()} 
  >
    <span className="sr-only">Toggle Dropdown</span>
  </button>
  {isDropdownOpen && ( // Conditionally render the dropdown menu
                  <div className="dropdown-menu show"> 
                    <button
                      type="button"
                      className="dropdown-item"
					  onClick={() => handleEditCategory(category.categoryid)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                    >
                      Delete
                    </button>
                  </div>
                )}
</div> */}



                              {/* <button
                                type="button"
                                className="btn btn-outline-success"
                                onClick={() => handleEditCategory(category)}
                              >
                                Edit
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 
			</div>

			{selectedCategory && (
  <EditCurrency
    category={selectedCategory}
    show={showEditModal} // Pass the value of showEditModal as isModalVisible
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
	fetchData={fetchData}
  />
)}

{selectedCategory && (
            <DeleteCategoryModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteCategoryConfirmed}
            />
          )}
	</div>
  );
}

export default CurrencyPage;
