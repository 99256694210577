// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Select, Button, DatePicker, Upload  } from 'antd';
import moment from 'moment';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ConfirmationModal from "../../../src/editCnfirmation/ConfirmationModal";
import ColorPaletteModal from '../../../src/colorPalaetteModal';
const EditProductModalforVendor = ({
  show,
  onHide,
  onSave,
  categoryList,
  category,
  product,
  productid,
  selectedProductTypeId
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [subcategoryList, setSubcategoryList] = useState([]);
    const [subSubCategoryList, setSubSubCategoryList] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploading1, setUploading1] = useState(false);
  const [imageResponses, setImageResponses] = useState([]);
  const [previewimage, setPreviewimage] = useState({});
  const [productinfo, setProductinfo] = useState(product);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [grouptypes, setGroupTypes] = useState([]);
  const [selectedProductTypeFields, setSelectedProductTypeFields] = useState([]);
const [initialGroupTypes, setInitialGroupTypes] = useState([]);
const [selectedColor, setSelectedColor] = useState('');
const [sizeOptions, setSizeOptions] = useState([]);
const handleSelectColor = (selectedHex) => {
  setSelectedColor(selectedHex);
};
  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        ...category,
        ...productinfo, // Populate the form fields with product data
        offerstart: product.offerstart ? moment(productinfo.offerstart, 'YYYY-MM-DD HH:mm') : null, 
        offerend: product.offerend ? moment(productinfo.offerend, 'YYYY-MM-DD HH:mm') : null,
      });
      setImageUrl(productinfo.productimage);
    }
    fetchSubcategories();
    fetchSubSubCategories();
    fetchGroupType();
  }, [show, category, form, productinfo]);
  

  const fetchSubcategories = async () => {
    try {
      const response = await axios.put('http://68.178.169.226:12080/api/v1/subCategory/get',{}, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      
      if (response.status === 401 || response.status === 404) {
        // Handle unauthorized or not found errors
        console.log('Unauthorized or not found error');
        return;
      }
      if (response.status === 200) {
        // Extract subcategory data from the response
        const subcategories = response.data;
  
        // Set the subcategory list state variable
        setSubcategoryList(subcategories);
      } else {
        console.error('Failed to fetch subcategories. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };
const fetchSubSubCategories = async () => {
  try{
    const response = await axios.put('http://68.178.169.226:12080/api/v1/subsubcategory/get',{},{
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 401 || response.status === 404) {
      console.log('Unauthorized or not found error');
      return;
    }
    if (response.status === 200) {
      const subsubcategories = response.data;
      setSubSubCategoryList(subsubcategories)
    } else {
      console.error('Failed to fetch subcategories. Status:', response.status);
    }
  } catch (error) {
    console.error('Error fetching subcategories:', error);
  }
}

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      onSave({ ...formData, color: selectedColor, productimage: imageUrl,previeworganiserid:JSON.parse(sessionStorage.getItem('previewfileids')) ,uploadfile:JSON.parse(sessionStorage.getItem('fileids') || '[]')});
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      console.log('You can only upload image files!');
    }
    return isImage;
  };
  const customRequestforProduct = async ({ file, onSuccess, onError }) => {
    try {
        const token = sessionStorage.getItem('tokenforseller');
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file); // Change 'image' to 'file' here
      const response = await axios.post(
        'http://68.178.169.226:12080/api/v1/fileupload/file',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const uploadedFile = response.data;
      uploadedFile.url = uploadedFile.file;

      console.log(response.data.organiserId);
      const fileids = JSON.parse(sessionStorage.getItem('fileids') ) || [];
      fileids.push(parseInt(response.data.organiserId));
      sessionStorage.setItem('fileids', JSON.stringify(fileids));
      setUploading(false);
      onSuccess();
      const organizerId = response.data.organiserId;
      const fileDetailsResponse = await axios.post(
        `http://68.178.169.226:12080/api/v1/fileupload/getbyid`,
        {fileid :organizerId},
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
            'Content-Type': 'application/json',
          },
        }
      );

      setImageResponses((prevResponses) => [
        ...prevResponses,
        fileDetailsResponse.data,
      ]);
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploading(false);
      onError();
    }
  };

  const prwviewImageRequest = async ({ file, onSuccess, onError }) => {
    try {
        const token = sessionStorage.getItem('tokenforseller');
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file); // Change 'image' to 'file' here
      const response = await axios.post(
        'http://68.178.169.226:12080/api/v1/fileupload/file',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      sessionStorage.setItem('previewfileids', response.data.organiserId);
      const fileDetailsResponse = await axios.post(
        `http://68.178.169.226:12080/api/v1/fileupload/getbyid`,
        {fileid : parseInt(response.data.organiserId)},
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
            'Content-Type': 'application/json',
          },
        }
      );
      setPreviewimage(fileDetailsResponse.data)
      setUploading(false);
      onSuccess();
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploading(false);
      onError();
    }
  };
  
  const getUploadButton = () => (
    <div>
      {uploading1 ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const getUploadButton1 = () => (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Change</div>
    </div>
  );
  const handleDeleteImageupload = async (file) => {
    try {
      console.log("filedetatils"+file)
      // Get the existing file IDs from sessionStorage or initialize as an empty array
      const fileids = JSON.parse(sessionStorage.getItem('fileids') || '[]');
      const indexToRemove = fileids.indexOf(file);
      if (indexToRemove !== -1) {
        // Remove the fileid from the array
        fileids.splice(indexToRemove, 1);
      
        // Save the updated fileids array back to session storage
        sessionStorage.setItem('fileids', JSON.stringify(fileids));
      
        console.log(`Removed fileid ${file}`);
      } else {
        console.log(`Fileid ${file} not found`);
      }
      for (let i = imageResponses.length - 1; i >= 0; i--) {
        if (imageResponses[i].fileid === file) {
          // Remove the element at index i
          imageResponses.splice(i, 1);
          break; // Exit the loop after removing the element
        }
      }
      
      // Update the state with the modified array
      setImageResponses([...imageResponses]);
      
      const response = await axios.post(
        'http://68.178.169.226:12080/api/v1/fileupload/delete',
        {
          fileid: file,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
      } else {
        console.error('Failed to delete file. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  const handleDeleteImage = async (index) => {
    try {
      const updatedFileModel = [...productinfo.fileModel];
      const deletedFile = updatedFileModel[index];

      // Remove the deleted file from the session storage array
      const fileids = JSON.parse(sessionStorage.getItem('fileids') || '[]');
      const updatedFileIds = fileids.filter((fileid) => fileid !== deletedFile.fileid);
      sessionStorage.setItem('fileids', JSON.stringify(updatedFileIds));

      // Hit the API to delete the file, passing the fileid in the request body
      const response = await axios.post(
        'http://68.178.169.226:12080/api/v1/fileupload/delete',
        {
          fileid: deletedFile.fileid,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
            'Content-Type': 'application/json',
          },
        }
      );
      

      if (response.status === 200) {
        let updatepro = productinfo.fileModel;
        if (productinfo && Array.isArray(updatepro)) {
          // Check if the index is valid
          if (index >= 0 && index < updatepro.length) {
            // Remove the element at the specified index
            updatepro = updatepro.slice(0, index).concat(updatepro.slice(index + 1));
          }
        }setProductinfo((prevProductInfo) => ({
          ...prevProductInfo,
          fileModel: updatepro,
        }));
        // If the file is successfully deleted from the server, update the form fields
        form.setFieldsValue({ fileModel: updatedFileModel });
      } else {
        console.error('Failed to delete file. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  const handleConfirmSaveChanges = () => {
    handleHideConfirmationModal();
    // Proceed with saving changes
    handleSave();
  }; 
  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };
  const fetchGroupType = async () => {
    try{
      const response = await axios.post('http://68.178.169.226:12080/api/v1/producttype/getforadmin',{},{
  headers: {
    Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
    'Content-Type': 'application/json',
  },
      });
      if(response.status === 401 || response.status === 404){
        console.log('Unauthorized or not found error');
        return;
      }
      if (response.status === 200) {
        const groupTypes = response.data;

                // Assuming selectedProductTypeId contains the ID of the selected product type
                const selectedProductType = groupTypes.find(item => item.producttypeid === selectedProductTypeId);

                // Then, extract the fields if the product type is found
                let selectedProductTypeFields = [];
                if (selectedProductType) {
                    selectedProductTypeFields = selectedProductType.fields;
                }
                setGroupTypes(groupTypes);
                setSelectedProductTypeFields(selectedProductTypeFields);
                
          await fetchSizeOptions(selectedProductTypeFields); 
      }else{
        console.error('Failed to fetch subcategories. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch group types and their fields
        const response = await axios.post('http://68.178.169.226:12080/api/v1/producttype/getforadmin', {}, {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
            'Content-Type': 'application/json',
          },
        });
  
        if (response.status === 200) {
          const groupTypes = response.data;
          const selectedProductType = groupTypes.find(item => item.producttypeid === selectedProductTypeId);
          let selectedProductTypeFields = [];  
          if (selectedProductType) {
            selectedProductTypeFields = selectedProductType.fields;
        }
        setGroupTypes(groupTypes);
        setSelectedProductTypeFields(selectedProductTypeFields);
        await fetchSizeOptions(selectedProductTypeFields); 
  
        } else {
          console.error('Failed to fetch group types. Status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching group types:', error);
      }
    };
  
    fetchData();
  }, [selectedProductTypeId]);

  const fetchSizeOptions = async (fields) => {
    try {
      if (fields.includes('Size')) {
        const response = await fetch('http://68.178.169.226:12080/api/v1/droupdown/getbydroupdown', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
          },
          body: JSON.stringify({ droupdown: 'size' }), 
        });
  
        if (response.ok) {
          const data = await response.json();
          setSizeOptions(data);
          console.log('Size Dropdown Options:', data);
          // Process the dropdown options as needed
          return data; // Return the response data
        } else {
          console.error('Failed to fetch size dropdown options');
          return null; // Return null if the request fails
        }
      } else {
        console.log('Size field is not included');
        return null; // Return null if 'Size' field is not included
      }
    } catch (error) {
      console.error('Error fetching size dropdown options:', error);
      return null; // Return null in case of an error
    }
  };
  return (
    <Modal
    title="Edit Product"
    visible={show}
    onCancel={onHide}
    footer={[
      <Button key="cancel" onClick={onHide}>
        Cancel
      </Button>,
      <Button key="save" type="primary" loading={loading} onClick={handleShowConfirmationModal}>
        Save
      </Button>,
    ]}
  >
    <Form form={form} layout="vertical">
    <Form.Item name="categoryid" label="Category">
          <Select>
            {categoryList.map((category) => (
              <Select.Option key={category.categoryid} value={category.categoryid}>
                {category.categoryname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="subcategoryid" label="Subcategory">
          <Select>
            {subcategoryList.map((subcategory) => (
              <Select.Option key={subcategory.subcategoryid} value={subcategory.subcategoryid}>
                {subcategory.subcategoryname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> 
        <Form.Item name="subsubcategoryid" label="Sub-Subcategory">
          <Select>
            {subSubCategoryList.map((subsubcategory) => (
              <Select.Option key={subsubcategory.subsubcategoryid} value={subsubcategory.subsubcategoryid}>
                {subsubcategory.subsubcategoryname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> 
  <>
  {selectedProductTypeFields && selectedProductTypeFields.includes('Color') && (
  <Form.Item name="color" label="Colour">
    {selectedColor ? (
      <div style={{ backgroundColor: selectedColor, width: '20px', height: '20px', marginLeft: '10px' }}></div>
    ) : (
      <Input type="color" style={{ width: '50px', height: '30px' }} disabled />
    )}
  </Form.Item>
)}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Color') && (
      <Form.Item name="color">
       <ColorPaletteModal onSelectColor={handleSelectColor}/>
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Color') && (
      <Form.Item name="colorname" label="Colour Name">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Size') && (
  <Form.Item name="size" label="Size">
     <Select>
     <Select.Option value="">Select Size</Select.Option>
     {sizeOptions.map((option) => (
       <Select.Option key={option.droupdownid} value={option.droupdownvalue}>
         {option.droupdownvalue}
       </Select.Option>
     ))}
   </Select>
  </Form.Item>
)}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Ram') && (
      <Form.Item name="ram" label="RAM">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Display') && (
      <Form.Item name="display" label="Display">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Displayunit') && (
      <Form.Item name="displayunit" label="Display Unit">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Storage') && (
      <Form.Item name="storage" label="Storage">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('os') && (
      <Form.Item name="os" label="OS">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Author') && (
      <Form.Item name="author" label="Author">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('capacity') && (
      <Form.Item name="capacity" label="Capacity">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('starrating') && (
      <Form.Item name="starrating" label="Rating">
        <Input />
      </Form.Item>
    )}
   {selectedProductTypeFields && selectedProductTypeFields.includes('type') && (
      <Form.Item name="type" label="Type">
        <Input />
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Unit') && (
      <Form.Item name="productunit" label="Type">
       <Select.Option value="Kg">Kg</Select.Option>
                                  <Select.Option value="G">g</Select.Option>
                                  <Select.Option value="L">L</Select.Option>
                                  <Select.Option value="ML">ml</Select.Option>
      </Form.Item>
    )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Material') && (
     <Form.Item name="material" label="material">
       <Input />
     </Form.Item>
   )}
    {selectedProductTypeFields && selectedProductTypeFields.includes('Packaging content') && (
     <Form.Item name="packagecontent" label="Packaging content">
       <Input />
     </Form.Item>
   )}
   {selectedProductTypeFields && selectedProductTypeFields.includes('Packaging weight') && (
     <Form.Item name="packageweight" label="Packaging weight">
       <Input />
     </Form.Item>
   )}
   {selectedProductTypeFields && selectedProductTypeFields.includes('Packaging unit') && (
     <Form.Item name="packagingunit" label="Packaging unit">
       <Input />
     </Form.Item>
   )}
   {selectedProductTypeFields && selectedProductTypeFields.includes('Weight') && (
     <Form.Item name="weight" label="Weight">
       <Input />
     </Form.Item>
   )}
  </>

        <Form.Item name="productname" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="producttags" label="Product Code">
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="sortdescription" label="Title (META TAG)">
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
</Form.Item>
<Form.Item name="fulldetails" label="Full Description">
          <CKEditor
          editor={ClassicEditor}
          data={product.fulldetails}
          onChange={(event, editor) => {
            const data = editor.getData();
            form.setFieldsValue({ fulldetails: data});
          }}
        />
        </Form.Item>
        <Form.Item name="quantity" label="Quantity">
          <Input />
        </Form.Item>
        <Form.Item name="mrpprice" label="MRP Price">
          <Input />
        </Form.Item>
        <Form.Item name="sellingprice" label="Selling Price">
          <Input />
        </Form.Item>
        {/* <Form.Item name="color" label="Colour">
          <Input />
        </Form.Item>
        <Form.Item name="colorname" label="Colour Name">
          <Input />
        </Form.Item> */}
        {/* <Form.Item name="productimage" label="Product Image">
        <Upload
            customRequest={customRequest}
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Product"
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            ) : (
              getUploadButton()
            )}
          </Upload>
</Form.Item> */}  
<Form.Item name="previewimg" label=" Main Image (Do not Repeat Main Image)">
  
  {previewimage.file ? (previewimage.file && (
            <div style={{ marginBottom: '10px' }}>
              <img
                src={previewimage.file}
                alt={`Preview Image`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
              />
            </div>
  )):(productinfo.previewimg && (
            <div style={{ marginBottom: '10px' }}>
              <img
                src={productinfo.previewimg.file}
                alt={`Preview Image`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
              />
            </div>
  ))}
          {<Upload
      customRequest={prwviewImageRequest}
      showUploadList={false}
      // beforeUpload={beforeUpload}
    >
      {getUploadButton1()}
    </Upload>}
  
          </Form.Item>
        <Form.Item name="fileModel" label="Other Image">
  {productinfo.fileModel &&
    productinfo.fileModel.map((file, index) => (
      <div key={index} style={{ marginBottom: '10px' }}>
        <img
          src={file.file}
          alt={`Product Image ${index + 1}`}
          style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
        />
        <Button onClick={() => handleDeleteImage(index)}>Delete</Button>
      </div>
    ))}
 <Upload
  customRequest={customRequestforProduct}
  showUploadList={false}
  listType="picture-card"
  // beforeUpload={beforeUpload}
  fileList={imageResponses} // Set the fileList to your imageResponses array
  itemRender={(originNode, file, fileList) => {
    return imageResponses.map((image, index) => (
      <div key={index} style={{ marginRight: '10px' }}>
        <img
          src={image.file}
          alt={`Uploaded Image ${index + 1}`}
          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
        />
        <Button onClick={() => handleDeleteImageupload(image.fileid)}>
          Delete
        </Button>
      </div>
    ));
  }}
>
  {getUploadButton()}
</Upload>
</Form.Item>

{imageResponses!=null && imageResponses && imageResponses.length>0 && (
        <Form.Item name="fileModel" label="Uploaded images">
          
    { imageResponses.map((image, index) => (
      <div key={index} style={{ marginRight: '10px' }}>
        <img
          src={image.file}
          alt={`Uploaded Image ${index + 1}`}
          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
        />
        <Button onClick={() => handleDeleteImageupload(image.fileid)}>
          Delete
        </Button>
      </div>
    ))}
        </Form.Item>

)

}
<Form.Item name="youtube" label="Youtube">
          <Input />
        </Form.Item>

        <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleHideConfirmationModal}
        onConfirm={handleConfirmSaveChanges}
      />
    </Form>
    </Modal>
  );
};

export default EditProductModalforVendor;
