import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Button,
  InputNumber,
  notification,
  Select,
  Row,
  Col,
} from "antd";
import axios from "axios";
import SideBar from "./../sidebar";
import Header from "./../Header";
import { useNavigate } from "react-router-dom";
import "./../../src/pagination.css";
import "./../style.scss";
import "./../riot_admin.scss";

const Quantity_update = () => {
  const navigate = useNavigate();
  const [productsList, setProductsList] = useState(() => {
    // Initialize from localStorage if available
    const savedProductsList = localStorage.getItem("productsList");
    return savedProductsList ? JSON.parse(savedProductsList) : [];
  });
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categorydata, setcategoryData] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subcategoryList, setSubCategoriesList] = useState([]);
  const [subsubcategoryList, setSubSubCategoriesList] = useState([]);
  useEffect(() => {
    fetchSubData();
  }, []);
  const fetchSubData = async (categoryid, subcategoryid) => {
    try {
      setLoading(true);
      // Construct the URL with query parameters
      const apiUrl = `http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory`;
      const requestBody = {
        categoryid: categoryid,
        subcategoryid: subcategoryid,
      };
      const response = await axios.put(apiUrl, requestBody, {
        headers: {
          //   'Authorization': 'Bearer ' + token,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setcategoryData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleNameSelect = (categoryid) => {
    try {
      setSelectedSubCategoryId(null);
      setSelectedSubSubCategoryId(null);
      setSelectedCategoryId(categoryid);
      const subCategoryData = categorydata.find(
        (item) => item.categoryid === categoryid
      );
      setSubCategoriesList(subCategoryData.subcategory);
    } catch {
      console.log("Error");
    }
  };
  const handleNameSubCategory = (subcategoryid) => {
    try {
      setSelectedSubCategoryId(subcategoryid);
      const subsCategoryData = subcategoryList.find(
        (item) => item.subcategoryid === subcategoryid
      );
      if (subsCategoryData != null) {
        if (subsCategoryData.subsubcategory) {
          setSubSubCategoriesList(subsCategoryData.subsubcategory);
        } else {
          setSubSubCategoriesList([]);
        }
      } else {
        setSubSubCategoriesList([]);
      }
      setSelectedSubSubCategoryId(null);
    } catch {
      console.log("Error");
    }
  };
  const handleNameSubSubCategory = (subsubcategoryid) => {
    try {
      setSelectedSubSubCategoryId(subsubcategoryid);
    } catch {
      console.log("Error");
    }
  };
  useEffect(() => {
    // Save productsList to localStorage whenever it changes
    const minimalUpdatedProducts = productsList.map(
      ({ productid, quantity }) => ({
        productid,
        quantity,
      })
    );
    localStorage.setItem(
      "productsList",
      JSON.stringify(minimalUpdatedProducts)
    );
  }, [productsList]);
  useEffect(() => {
    if (selectedCategoryId) {
      fetchData();
    }
  }, [selectedCategoryId, selectedSubCategoryId, selectedSubSubCategoryId]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getByCategory1",
        {
          categoryid: selectedCategoryId,
          subcategoryid: selectedSubCategoryId ? selectedSubCategoryId : null,
          subsubcategoryid: selectedSubSubCategoryId
            ? selectedSubSubCategoryId
            : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      setData(data);
      setCurrentPage(response.data.Currentpageno);
      setTotalPages(response.data.Totalpageno);

      if (response.data.Totalpageno <= 1) {
        setPageNumbers([]);
      } else {
        const numbersArray = Array.from(
          { length: response.data.Totalpageno },
          (_, index) => index + 1
        );
        setPageNumbers(numbersArray);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const changePage = async (pageNumber) => {
    if (pageNumber >= 0 && pageNumber <= totalPages) {
      fetchData(pageNumber);
    }
  };
  const renderPageNumbers = () => {
    const maxVisiblePages = 6;
    const delta = Math.floor(maxVisiblePages / 2);
    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map((number) => (
        <li
          key={number}
          className={currentPage === number - 1 ? "selected-page" : ""}
          onClick={() => changePage(number - 1)}
        >
          {number}
        </li>
      ));
    }
    if (currentPage <= delta) {
      return (
        <>
          {pageNumbers.slice(0, maxVisiblePages - 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
          <li>...</li>
          <li onClick={() => changePage(totalPages - 1)}>{totalPages}</li>
        </>
      );
    }
    if (currentPage >= totalPages - delta) {
      return (
        <>
          <li
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => changePage(0)}
          >
            1
          </li>
          <li>...</li>
          {pageNumbers.slice(totalPages - maxVisiblePages + 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
        </>
      );
    }
    return (
      <>
        <li
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => changePage(0)}
        >
          1
        </li>
        <li>...</li>
        {pageNumbers
          .slice(currentPage - delta, currentPage + delta)
          .map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => changePage(number - 1)}
            >
              {number}
            </li>
          ))}
        <li>...</li>
        <li
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => changePage(totalPages - 1)}
        >
          {totalPages}
        </li>
      </>
    );
  };
  const handleOk = (product, value) => {
    try {
      setSelectedProduct(product);
      const updatedProducts = productsList.some(
        (p) => p.productid === product.productid
      )
        ? productsList.map((p) =>
            p.productid === product.productid ? { ...p, quantity: value } : p
          )
        : [...productsList, { ...product, quantity: value }];

      setProductsList(updatedProducts);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async () => {
    try {
      const minimalUpdatedProducts = productsList.map(
        ({ productid, quantity }) => ({
          productid,
          quantity,
        })
      );
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/product/editquantity",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(minimalUpdatedProducts),
        }
      );
      if (response.ok) {
        const result = await response.json();
        const successfulProductIds = result.successfulProductIds || [];
        // Remove succeeded products from productsList
        const updatedProductsList = productsList.filter(
          (product) =>
            Object.keys(product).length > 0 &&
            !successfulProductIds.includes(product.productid)
        );
        setProductsList(updatedProductsList);
        localStorage.setItem(
          "productsList",
          JSON.stringify(updatedProductsList)
        );
        notification.success({
          message: "Success",
          description: "Products list updated successfully.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to update products list.",
        });
      }
    } catch (error) {
      console.error("Error updating products list:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while updating products list.",
      });
    } finally {
      fetchData();
    }
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
    },
    {
      title: "SubCategory",
      dataIndex: "subcategoryname",
      key: "subcategoryname",
    },
    {
      title: "SubSubCategory",
      dataIndex: "subsubcategoryname",
      key: "subsubcategoryname",
    },
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    {
      title: "MRP Price",
      dataIndex: "mrpprice",
      key: "mrpprice",
      sorter: (a, b) => a.mrpprice - b.mrpprice,
    },
    {
      title: "Selling Price",
      dataIndex: "sellingprice",
      key: "sellingprice",
      sorter: (a, b) => a.sellingprice - b.sellingprice,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Changed Quantity",
      dataIndex: "Changed_quantity",
      key: "quantity",
      render: (text, record) => {
        const product = productsList.find(
          (e) => e.productid === record.productid
        );
        return product ? (
          <InputNumber
            min={0}
            value={product.quantity}
            onChange={(value) => handleOk(record, value)}
          />
        ) : (
          <InputNumber
            min={0}
            //  value={product.quantity}
            onChange={(value) => handleOk(record, value)}
          />
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <div className="row">
            <Header />
            {loading ? (
              <div className="container-fluied">
                <div className="row">
                  <div className="muti-col-loader"></div>
                </div>
              </div>
            ) : (
              <div>
                <Row className="m-5">
                  <Col span={6}>
                    <Select
                      style={{ width: "60%" }}
                      value={selectedCategoryId}
                      onChange={(value) => handleNameSelect(value)}
                      placeholder="Select Category"
                    >
                      {categorydata.map((name) => (
                        <Select.Option
                          key={name.categoryid}
                          value={name.categoryid}
                        >
                          {name.categoryname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      placeholder="Select Sub Category"
                      style={{ width: "60%" }}
                      onChange={(value) => handleNameSubCategory(value)}
                      value={selectedSubCategoryId}
                    >
                      {subcategoryList.map((name) => (
                        <Select.Option
                          key={name.subcategoryid}
                          value={name.subcategoryid}
                        >
                          {name.subcategoryname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      placeholder="Select Sub Sub Category"
                      style={{ width: "60%" }}
                      onChange={(value) => handleNameSubSubCategory(value)}
                      value={selectedSubSubCategoryId}
                    >
                      {subsubcategoryList.map((name) => (
                        <Select.Option
                          key={name.subsubcategoryid}
                          value={name.subsubcategoryid}
                        >
                          {name.subsubcategoryname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  {productsList &&
                    productsList.length > 0 &&
                    selectedCategoryId && (
                      <Col span={6}>
                        <Button type="primary" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </Col>
                    )}
                </Row>

                {selectedCategoryId ? (
                  <>
                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey="productid"
                      scroll={{ x: "max-content" }}
                      pagination={false}
                    />

                    {totalPages > 1 && (
                      <ul className="d-flex justify-content-center pagination">
                        <li
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() => changePage(currentPage - 1)}
                        >
                          {"<"}
                        </li>
                        {renderPageNumbers()}
                        <li
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() => changePage(currentPage + 1)}
                        >
                          {">"}
                        </li>
                      </ul>
                    )}
                  </>
                ) : (
                  <p>Please select a category</p>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Quantity_update;
