import React, { useState, useEffect } from 'react';
import '../style.scss';
import '../riot_admin.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { Select,DatePicker  } from 'antd';
import LoginHeader from '../componant/LoginHeader';

const Stage1 = () => {
  const token = sessionStorage.getItem('tokenforseller');
  const navigate = useNavigate();
  const [pan, setPan] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [dob, setDob] = useState('');
  const [data, setData] = useState([]);
  const [clientId, setClientId] = useState(null);
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'http://68.178.169.226:12080/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setClientId(responseData.id); // Store the client ID in state
          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    try {
      // TODO: Replace with actual API call
      const response = await fetch("http://68.178.169.226:12080/api/v1/seller/edit", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
        pan,
          aadhar,
          dob,
          sellerid:clientId
        }),
      });
  
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }
  
      if (response.ok) {
        const data = await response.json();
        console.log("POST request successful");
        navigate('/Stage2');
        if (response.data && response.data.length > 0) {
            setData(response.data);
          } else {
            setData([]); // If no data is received, set an empty array
          }
        // window.location.reload(); // Refresh the page
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleLogout = () => {
    sessionStorage.clear();
  navigate('/AdminLogin');
  };
  return (
    
<div>
    <LoginHeader/>
    <div className='registration-container'>
        <div className='container text-center pb-4'>
       <p className='fs-4'> Thank you for choosing Shopeasey as your platform for selling. We appreciate your interest in joining our community of sellers. 

During the registration process, we are carefully reviewing your information to ensure the highest quality experience for both sellers and buyers.</p>
        </div>
        <div className='registration-process  container d-flex justify-content-center'>
          <div className='registration-process-done'>
          <span> 1 </span> Personal Details
            </div>
          <div className='registration-process-active'>
          <span> 2 </span> Business Details
          </div>
          <div className='registration-process-pending'>
          <span> 3 </span> Plan & Payment
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <div className="registration">
        <form onSubmit={onFinish}>
                         
                         <div className="form-group row">
                           <label htmlFor="text" className="col-12 col-form-label">
                             PanCard No.
                           </label>
                           <div className="col-12">
                             <input
                               id="text"
                               name="text"
                               className="form-control here slug-title"
                               type="text"
                               value={pan}
                               onChange={(e) => setPan(e.target.value)}
                               autoComplete="off"
                               required
                             />
                           </div>
                         </div>
 
                         <div className="form-group row">
                           <label htmlFor="slug" className="col-12 col-form-label">
                             AadharCard No.
                           </label>
                           <div className="col-12">
                             <input
                               id="slug"
                               name="slug"
                               className="form-control here set-slug"
                               type="text"
                               value={aadhar}
                               onChange={(e) => setAadhar(e.target.value)}
                               autoComplete="off"
                               required
                             />
                           </div>
                         </div>
 
                         <div className="form-group row">
                           <label className="col-12 col-form-label">Dob</label>
                           <div className="col-12">
                             <DatePicker
                               id="slug"
                               name="slug"
                               className="form-control here set-slug"
                               type="text"
                               value={dob}
                               onChange={(dateString) => setDob(dateString)}
                               autoComplete="off"
                               required
                             />
                           </div>
                         </div>
                         <div className="row">
                           <div className="col-12">
                             <button name="submit" type="submit" className="btn btn-primary">
                               Submit
                             </button>
                           </div>
                         </div>
                       </form>

                       
        </div>
        </div>
        </div>
 </div>
  );
};

export default Stage1;
