import React, { useState, useEffect } from 'react';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Modal, Button  } from 'antd';
import axios from "axios";
import {Select } from "antd";
import EditSubCategoryModal from './EditSubCategory';

const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
	  <Modal
		visible={visible}
		title="Confirm Deletion"
		onCancel={onCancel}
		footer={[
		  <Button key="cancel" onClick={onCancel}>
			Cancel
		  </Button>,
		  <Button key="delete" type="primary" onClick={onConfirm}>
			Delete
		  </Button>,
		]}
	  >
		<p>Are you sure you want to delete this subcategory?</p>
	  </Modal>
	);
  };
const SubCategory = () => {

	const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	  const [subcategoryname, setSubCategoryname] =useState('');
	  const [slug, setSlug] =useState('');
	  const [sortdescription, setSortdescription] =useState('');
	  const [fulldescription, setFulldescription] =useState('');
	  const [producttag, setProducttag] =useState('');
	  const [categoryList, setCategoriesList] = useState([]);
	  const [selectedCategoryId, setSelectedCategoryId] = useState('');
	  const [showDeleteModal, setShowDeleteModal] = useState(false);

	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	//   const handleDeleteCategory = async (subcategoryId) => {
	// 	try {
	// 	  setLoading(true);
	  
	// 	  // Make an API request to delete the category
	// 	  const response = await axios.post(
	// 		`http://68.178.169.226:12080/api/v1/subCategory/delete`,
	// 		{
	// 		  subcategoryid: subcategoryId, // Pass the categoryId as a payload
	// 		},
	// 		{
	// 		  headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		  },
	// 		}
	// 	  );
	  
		  
    // // Check if the deletion was successful
    // if (response.status === 200) {
	// 	// The category was deleted successfully
	// 	console.log('Category deleted successfully');
  
	// 	// Reload the page to reflect the changes
	// 	window.location.reload();
	//   } else {
	// 	console.error('Failed to delete category');
	//   }
  
	//   setLoading(false);
	// 	} catch (error) {
	// 	  console.error('Error deleting category:', error);
	// 	  setLoading(false);
	// 	}
	//   };
	const handleDeleteCategoryConfirmed = async () => {
		try {
		  setLoading(true);
	
		  // Make an API request to delete the category
		  const response = await axios.post(
			`http://68.178.169.226:12080/api/v1/subCategory/delete`,
			{
				subcategoryid: selectedCategory,
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 200) {
			// Refresh the page or update the category list
			fetchData();
		  } else {
			console.error('Failed to delete category');
		  }
		} catch (error) {
		  console.error('Error deleting category:', error);
		} finally {
		  setLoading(false);
		  setShowDeleteModal(false);
		}
	  };
	  // Function to close the edit modal
	  const handleSaveEdit = async (editedData) => {
		try {
		  setLoading(true);
		  editedData.subcategoryid = selectedCategory.subcategoryid;
		  editedData.categoryname = selectedCategory.categoryname;
		  // Make an API request to update the subcategory data
		  const response = await axios.post(
			`http://68.178.169.226:12080/api/v1/subCategory/edit`,
			editedData,
			{
			  headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return;
		  }
	
		  if (response.status === 200) {
			console.log('Subcategory updated successfully');
			window.location.reload();
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error('PUT request failed');
		  }
		} catch (error) {
		  console.error('Error updating data:', error);
		} finally {
		  setLoading(false);
		  handleCloseEditModal(); // Close the modal whether the request succeeds or fails
		}
	  };

	  useEffect(() => {
			const fetchCategoryNames = async () => {
				try {
					const response = await axios.put("http://68.178.169.226:12080/api/v1/category/get",{},{
					  headers: {
						'Authorization': 'Bearer ' +token,
						'Content-Type': 'application/json',
					  },
					});
					if (response.status === 401 || response.status === 404) {
					  // Redirect to the login page for 401 or 404 errors
					  navigate('/AdminLogin');
					  return; // Exit early to prevent further logic execution
					}
					setCategoriesList(response.data);
				} catch (error) {
					console.error("Error fetching names:", error);
				}
			};
	
			fetchCategoryNames();
		
	}, []);

	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once

	  const fetchData = async () => {
		try {
		  const response = await axios.put("http://68.178.169.226:12080/api/v1/subCategory/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };


	  const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
	  
		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("http://68.178.169.226:12080/api/v1/subCategory/add", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			subcategoryname,
			  slug,
			  sortdescription,
			  fulldescription,
			  producttag,
			  categoryid:selectedCategoryId,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };

	  const handleNameSelect = (categoryid) => {
		setSelectedCategoryId(categoryid);
	  };
	  const handleDeleteCategory = (categoryId) => {
		setSelectedCategory(categoryId);
		setShowDeleteModal(true);
	  };
    return(
        <div class="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		{/* <!-- PAGE WRAPPER --> */}
		<div class="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div class="ec-content-wrapper">
				<div class="content" >
					<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
						<h1>Sub Category</h1>
					</div>
					<div class="row">
						<div class="col-xl-4 col-lg-12">
							<div class="ec-cat-list card card-default mb-24px">
								<div class="card-body">
									<div class="ec-cat-form">
										<h4>Add Sub Category</h4>
										<h6>Maximun length of Description (META TAG) is 1400 words,Others 250 words including space.</h6>
										<form
										onSubmit={onFinish}
										>
											
											<div className="form-group row">
  <label htmlFor="parent-category" className="col-12 col-form-label">Parent Category</label> 
  <div className="col-12">
    <Select
      style={{ width: '60%', border: '2px solid #8f8f8f' }}
      onChange={(value) => handleNameSelect(value)} // Pass a function reference
    >
      {categoryList.map((name) => (
        <Select.Option key={name.categoryid} value={name.categoryid}>
          {name.categoryname}
        </Select.Option>
      ))}
    </Select>
  </div>
</div>

											<div class="form-group row">
												<label for="text" class="col-12 col-form-label">Name</label> 
												<div class="col-12">
													<input id="text" name="text" class="form-control here slug-title" type="text"value={subcategoryname} onChange={(e) => setSubCategoryname(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div class="form-group row">
												<label for="slug" class="col-12 col-form-label">Slug</label> 
												<div class="col-12">
													<input id="slug" name="slug" class="form-control here set-slug" type="text"value={slug} onChange={(e) => setSlug(e.target.value)} autoComplete="off" />
													<small>The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</small>
												</div>
											</div>

											<div class="form-group row">
												<label class="col-12 col-form-label">Title (META TAG)</label> 
												<div class="col-12">
													<textarea id="sortdescription" name="sortdescription" cols="40" rows="2" class="form-control" value={sortdescription} onChange={(e) => setSortdescription(e.target.value)}></textarea>
												</div>
											</div> 



											<div class="form-group row">
												<label class="col-12 col-form-label">Description (META TAG)</label> 
												<div class="col-12">
													<textarea id="fulldescription" name="fulldescription" cols="40" rows="4" class="form-control"value={fulldescription} onChange={(e) => setFulldescription(e.target.value)}></textarea>
												</div>
											</div> 

											<div class="form-group row">
												<label class="col-12 col-form-label">Product Tags <span>( Type and
														make comma to separate tags )</span></label>
												<div class="col-12">
													<input type="text" class="form-control" id="group_tag" name="group_tag"  placeholder="" data-role="tagsinput" value={producttag} onChange={(e) => setProducttag(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div class="row">
												<div class="col-12">
													<button name="submit" type="submit" class="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-8 col-lg-12">
							<div class="ec-cat-list card card-default">
								<div class="card-body">
									<div class="table-responsive">
										<table id="responsive-data-table" class="table">
											<thead>
												<tr>
													<th>Parent Category</th>
													<th>Name</th>
													<th>Slug</th>
													<th>Title (META TAG)</th>
													<th>Description (META TAG)</th>
													<th>Product Tags</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((category) => (
                          <tr key={category.categoryid}>
						  <td>{category.categoryname}</td>
                            <td>{category.subcategoryname}</td>
                            <td>{category.slug}</td>
                            <td>{category.sortdescription}</td>
                            <td>{category.fulldescription}</td>
                            <td>{category.producttag}</td>
                            <td>
							<div className="btn-group">
    <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleEditCategory(category);
      }}
    >
      Edit
    </a>
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.subcategoryid);
      }}
    >
      Delete
    </a> 
  </div>                </td>
                          </tr>
                        ))}
                      </tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> 
			</div> 

			
			{selectedCategory && (
  <EditSubCategoryModal
  show={showEditModal}
  onHide={handleCloseEditModal}
  onSave={handleSaveEdit}
  categoryList={categoryList}
  category={selectedCategory}
/>
)}
{selectedCategory && (
            <DeleteCategoryModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteCategoryConfirmed}
            />
          )}
		</div> 

	</div> 
  
    );
}
export default SubCategory;